import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Loader } from 'react-loaders';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { partyActions, requestActions, billingInformationActions } from '../../actions';

import { ContactInfo, MainContainer } from '../../components';
import { /* EmailValidator, */ validatePassRequiredFields } from '../../components/validator/validator';
import { mapDispatchToProps, mapStateToProps } from '../../helpers';
import widgetConfig from '../../widgetConfig';
import './assign-passes.css';
import FormField from '../../components/form-field/form-field';
import FormattedPrice from '../../components/formatted-price/formatted-price';

const PaymentComponent = ({
  isFilled, hasPhoneOrEmail, documentUploadRequired, createParty, onClickWhenMissing, fullDiscountInEffect,
}) => (
  <div className='row'>
    {widgetConfig.retailerType === 'park' && !hasPhoneOrEmail && (
      <div className="alert alert-warning">
        Passes must be printed if no phone or email address is entered
      </div>
    )}
    <div className='col-xs-12 col-no-padding payment-group-buttons'>
      { // show Commbank only for non-parks
        (widgetConfig.widgetType === 'fit' && widgetConfig.retailerType !== 'park') && (
          <button
            type='button'
            className={`btn btn-primary ${!isFilled ? 'disabled' : ''}`}
            title={isFilled ? '' : 'Please fill all required fields to proceed'}
            onClick={() => {
              if (isFilled) createParty('commbank');
              if (!isFilled) {
                onClickWhenMissing();
              }
            }}
          >
            {fullDiscountInEffect ? (
              <strong>Complete Purchase</strong>
            ) : (
              <Fragment>
                <p>
                  <span className='fa fa-lock fa-lg icon-lock' />
                  <span className='pay-now-label'>
                    <strong>{documentUploadRequired ? 'NEXT' : 'PAY NOW' }</strong>
                  </span>
                </p>
                {!documentUploadRequired && (
                  <span>
                    <i className='fa fa-cc-mastercard fa-2x' />
                    <i className='fa fa-cc-visa fa-2x' />
                  </span>
                )}
              </Fragment>
            )}
          </button>
        )
      }

      {
        (widgetConfig.widgetType !== 'fit' && widgetConfig.retailerType !== 'park') && (
          <button
            className='btn btn-success btn-payment-button'
            disabled={!isFilled}
            onClick={() => createParty('commbank')}
          >
            {fullDiscountInEffect ? (
              'Continue'
            ) : (
              <Fragment>
                <i className='fa fa-credit-card' />
                Pay Now
              </Fragment>
            )}
          </button>
        )
      }

      {
        (widgetConfig.retailerType === 'park') && (
          <React.Fragment>
            <button
              className='btn btn-payment-button btn-success'
              disabled={!isFilled || !hasPhoneOrEmail}
              onClick={() => createParty('parks_internal')}
            >
              {fullDiscountInEffect ? 'Create Pass' : 'Payment Received'}
            </button>
            <button
              className='btn btn-payment-button btn-success'
              disabled={!isFilled}
              onClick={() => createParty('parks_internal', 'pdf-print')}
            >
              {fullDiscountInEffect ? 'Create Pass & Print' : 'Payment Received & Print'}
            </button>
            <button
              className='btn btn-payment-button btn-default'
              disabled={!isFilled}
              onClick={() => createParty('parks_internal', 'pdf-print-manifest')}
            >
              Print Manifest
            </button>
          </React.Fragment>
        )
      }

      {(
        !fullDiscountInEffect &&
        widgetConfig.showCreditPaymentMethod &&
        widgetConfig.widgetType !== 'fit' &&
        widgetConfig.retailerType !== 'park'
      ) &&
        <button
          className='btn btn-payment-button btn-info'
          disabled={!isFilled}
          onClick={() => createParty('credit')}
        >
          <i className='fa fa-list-alt' />
          Charge Account
        </button>
      }
    </div>
  </div>
);

PaymentComponent.propTypes = {
  isFilled: PropTypes.bool.isRequired,
  createParty: PropTypes.func.isRequired,
  onClickWhenMissing: PropTypes.func,
};

PaymentComponent.defaultProps = {
  onClickWhenMissing: () => {},
};

class AssignPasses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { requestError } = nextProps;
    if (requestError && requestError.errorType === 'RECAPTCHA_ERROR') {
      this.setState({ createRecaptcha: true });
      window.recaptchaMustBeUpdated = true;
    }
  }

  componentDidUpdate() {
    if (this.state.createRecaptcha) {
      if (window.isGrecaptchaRendered && window.recaptchaMustBeUpdated) {
        window.grecaptcha.reset();
        window.recaptchaMustBeUpdated = false;
      }
      if (!this.state.loaded) {
        if (!window.isGrecaptchaRendered) {
          window.isGrecaptchaRendered = true;
          window.recaptchaMustBeUpdated = false;
          const { recaptachaKey } = widgetConfig;

          window.grecaptcha.render(this.recaptchaElement, {
            sitekey: recaptachaKey,
            callback: (response) => {
              this.setState({
                recaptchaResponse: response,
              });
            },
          });
        }
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ loaded: true });
      }
    }
  }

  getTotalChange = () => {
    const { billingContact } = this.props;
    const changeAmount = billingContact.get('cashTendered') - billingContact.get('totalAmount');
    if (Number.isNaN(changeAmount)) return 0;
    if (changeAmount < 0) return '...';
    return changeAmount.toFixed(2);
  }

  getVoidSaleUrl = () => {
    const { party } = this.props;
    let url = `${party.get('void_sale_url')}?next=`;
    if (widgetConfig.org_id) {
      url = `${url}/${widgetConfig.org_id}`;
    }
    url = `${url}/passes/${party.get('parkSlug')}/`;

    if (widgetConfig.mobileView) {
      url = `${url}%3FmobileView=${widgetConfig.mobileView}`;
    }

    return url;
  }

  createParty(paymentMethod, printManifest = false, fullDiscountInEffect) {
    // printManifest is false, render-print, pdf-print
    const { party } = this.props;
    const totalAmount = fullDiscountInEffect ? 0 : window.parseFloat(party.get('contactData').get('totalAmount', 0));
    window.showSellAnotherPassLink = (
      (printManifest == 'pdf-print') || (printManifest == 'pdf-print-manifest')
    );

    let parsedCashTendered = this.props.billingContact.get('cashTendered');
    if (!parsedCashTendered || parsedCashTendered < 0) {
      parsedCashTendered = null;
    }

    this.props.postParty(
      party.set('paymentMethod', totalAmount > 0 ? paymentMethod : 'free')
        .set('recaptchaResponse', this.state.recaptchaResponse)
        .set('isPrintPartyManifest', printManifest)
        .set('cashTendered', parsedCashTendered)
        .set('merch', this.props.merch),
    );
  }

  handleClickMissing = () => {
    const { party } = this.props;
    const tickets = party.get('tickets').toJS();
    for (let i = 0; i < tickets.length; i = i + 1) {
      const isFocused = this[`contactInfo${i}`].checkFocus();
      if (isFocused) break;
    }
  }

  cashTenderedChangeHandler = (value) => {
    if (value.length > 10) {
      return;
    }
    const cashTendered = parseFloat(value.replace(',', '.').trim(), 10);
    if (cashTendered < 0 || cashTendered > 10000000) {
      return;
    }
    this.props.updateBillingInformation(
      this.props.billingContact.set('cashTendered', cashTendered),
    );
  }

  render() {
    const {
      history, party, countries, errors, requestError, resetRequestError, canCreateParty,
      billingContact, arrivalInformation,
    } = this.props;
    const {
      widgetType, org_id, bearerToken, retailerType,
    } = widgetConfig;
    const clientPaymentMethod = billingContact.get('clientPaymentMethod');
    const fullDiscountInEffect = arrivalInformation.get('fullDiscountInEffect');
    const hasPhoneOrEmail = ['email', 'phone'].some(field => Boolean(billingContact.get(field)?.trim()));
    if (!party) {
      return <Redirect to='/' />;
    }
    const tickets = party.get('tickets');

    let areAllContactRowsFilled = true;
    let documentUploadRequired = false;

    tickets.toJS().forEach((ticket) => {
      // for assign passes step we put errors here
      if (!validatePassRequiredFields(ticket)) {
        areAllContactRowsFilled = false;
      }
      if (ticket.ticketValidationRules.document_upload_required) {
        documentUploadRequired = true;
      }
    });

    const isFilled = errors.size === 0 && areAllContactRowsFilled;

    const amount = party.get('contactData').get('totalAmount');
    const goBack = (evt) => {
      if (history.length > 0) {
        history.goBack();
        evt.preventDefault();
        evt.stopPropagation();
      }
    };

    return (
      <MainContainer
        requestError={requestError}
        resetRequestError={resetRequestError}
        className='assign-passes'
      >
        <div className='form-heading'>
          <Link
            className="pull-left"
            to={`${widgetConfig.url_prefix}/passes/${party.get('parkSlug')}/`}
            onClick={goBack}
            aria-label="Back"
          >
            <i className='fa fa-chevron-left' />
          </Link>
          <h1>{tickets.size ? 'Assign Your Park Passes' : 'Payment'}</h1>
        </div>
        {tickets.size > 0 && (
          <div className='alert alert-info'>
            Please enter visitor names.
            Your pass will be sent to the email or mobile phone numbers provided.
            Vehicle registration may be updated later.
          </div>
        )}
        <form>
          {tickets.map((ticket, id) => (
            <ContactInfo
              ref={(contactInfo) => { this[`contactInfo${id}`] = contactInfo; }}
              errors={errors.filter(error => error.id === id)}
              key={id}
              className={(id + 1) % 2 ? 'odd' : ''}
              ticket={ticket}
              countries={countries}
              onChange={
                changedTicket => this.props.updatePartyTicket({ id, ticket: changedTicket })
              }
            />
          ))}
          <div className='total-price-wrapper'>
            {widgetType !== 'retail' ?
              (
                <h3>
                  <strong>Total: </strong>
                  <FormattedPrice value={amount} />
                </h3>
              ) : (
                <h3>
                  <strong>Total Retail Price: </strong>
                  <FormattedPrice value={amount} />
                </h3>
              )
            }
          </div>

          {!(!org_id && !bearerToken) && retailerType === 'park' && clientPaymentMethod === 'Cash' && (
            <div className='cash-payment-wrapper'>
              <div className='cash-tendered'>
                <FormField
                  refInput={(inputComponent) => { this.cashTendered = inputComponent; }}
                  name='cash-tendered'
                  type='number'
                  label='Cash tendered:'
                  value={billingContact.get('cashTendered')}
                  onChange={this.cashTenderedChangeHandler}
                />
              </div>
              <div className='cash-change'>
                <span className='cash-change__label'>Change: </span>
                <span className='cash-change__amount'>${this.getTotalChange()}</span>
              </div>
            </div>
          )}
        </form>

        <div className='row'>
          <div className='col-xs-12'>
            <div
              id='recaptcha'
              ref={(recaptchaElement) => { this.recaptchaElement = recaptchaElement; }}
            />
          </div>
        </div>
        <div className='total-price-wrapper'>
          {
            canCreateParty ? (
              <PaymentComponent
                onClickWhenMissing={this.handleClickMissing}
                isFilled={isFilled}
                hasPhoneOrEmail={hasPhoneOrEmail}
                documentUploadRequired={documentUploadRequired}
                fullDiscountInEffect={fullDiscountInEffect}
                createParty={
                  (paymentMethod, printManifest) =>
                    this.createParty(paymentMethod, printManifest, fullDiscountInEffect)
                }
              />
            ) : (
              <span>
                <Loader
                  styles={{ margin: '20px auto' }}
                  className='text-center'
                  type='ball-pulse-sync' color='#00c1a9'
                />
                { window.showSellAnotherPassLink && (
                  <span>
                    <div className='messsage alert alert-warning' role='alert'>
                      Please wait for passes to be printed before selling another pass
                    </div>
                    <button
                      className='btn btn-lg btn-success'
                      onClick={() => { window.mayBeReloadedAfterPrint = true; }}
                    >
                      Sell another pass
                    </button>

                  </span>
                )}

                { window.showSellAnotherPassLink && party.get('void_sale_url') && (
                  <a
                    href={this.getVoidSaleUrl()}
                    className='btn btn-lg btn-danger'
                    style={{ marginLeft: '10px' }}
                  >
                    Void Sale
                  </a>
                )}

              </span>
            )
          }
        </div>
      </MainContainer>
    );
  }
}


export default connect(
  mapStateToProps(
    'party', 'countries', 'errors', 'requestError', 'canCreateParty',
    'billingContact', 'arrivalInformation', 'merch',
  ),
  mapDispatchToProps(partyActions, requestActions, billingInformationActions),
)(AssignPasses);
