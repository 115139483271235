/* eslint-disable react/prefer-stateless-function */
import PropTypes from 'prop-types';
import React from 'react';

import MerchItem from '../merch-item/merch-item';
import MerchGridItem from '../merch-item/merch-grid-item';
import FormattedPrice from '../formatted-price/formatted-price';

export default class MerchChoose extends React.Component {
  static propTypes = {
    billingInformation: PropTypes.object.isRequired,
    merch: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    mobileView: PropTypes.bool,
    gridView: PropTypes.bool,
  };

  static defaultProps = {
    mobileView: false,
    gridView: false,
  };

  render() {
    const { billingInformation, merch } = this.props;

    return (
      <div className='ticket-choose'>
        <ul className='nav nav-tabs nav-justified'>
          <li className="active">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
              <strong>Merchandise</strong>
            </a>
          </li>
        </ul>
        <div className='row flex-box row-table row-table-bordered row-table-head ticket-type-table-head'>
          <div className='pass-type-head'>
            <strong>Product</strong>
          </div>
          {!this.props.gridView && (
          <div className='flex-box price-qty-head big-device'>
            <div className='cell-center text-right price-head'>
              <strong>Size</strong>
            </div>
            <div className={`cell-center qty-head ${this.props.mobileView && 'touch-screen-mode'}`}>
              <strong>Quantity</strong>
            </div>
          </div>
          )}
        </div>
        <div className="ticket-choose-items scrollable">
          <div className="ticket-choose-scroller">
            {this.props.gridView ? (
              <div className="merch-grid">
                {merch.map(merchItem => (
                  <MerchGridItem
                    key={merchItem.get('id')}
                    merchItem={merchItem}
                    mobileView={this.props.mobileView}
                    onChange={this.props.onChange}
                  />
                ))}
              </div>
            ) : (
              merch.map(merchItem => (
                <MerchItem
                  className='row row-table'
                  key={merchItem.get('id')}
                  merchItem={merchItem}
                  mobileView={this.props.mobileView}
                  onChange={this.props.onChange}
                />
              ))
            )}
          </div>
        </div>
        <div className='row row-table row-table-bordered'>
          <div className='result-table'>
            <span className='col-no-padding'>
              <small>Total quantity:</small>
              <h4>Total Price:</h4>
            </span>
            <span className='col-no-padding total-price-number'>
              <small>
                {billingInformation.get('totalMerchQty')}
                <h4><FormattedPrice value={billingInformation.get('totalMerchAmount')} /></h4>
              </small>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
