import { createSimpleAction } from '../helpers';

const updateArrivalInformation = createSimpleAction((arrivalInformation, dispatch, getState) => {
  const { ticketTypes } = getState();
  const selectedTickets = ticketTypes.filter(ticketType => ticketType.get('count') > 0);
  // get min value of valid_for_default from the list of selected tickets
  const minDefaultValueTicketType = selectedTickets.minBy((ticketType) => {
    const valid_for_default = ticketType.get('valid_for_default');
    if (valid_for_default) {
      const [number, letter] = valid_for_default.match(/[a-z]+|[^a-z]+/gi);
      let totalDays = parseInt(number, 10);
      if (letter === 'm') {
        totalDays = totalDays * 30; // 30 days per month
      }
      return totalDays;
    }
    return 9999;
  });

  // get min value of valid_for_max from the list of selected tickets
  const minMaxValueTicketType = selectedTickets.minBy((ticketType) => {
    const valid_for_max = ticketType.get('valid_for_max');
    if (valid_for_max) {
      const [number, letter] = valid_for_max.match(/[a-z]+|[^a-z]+/gi);
      let totalDays = parseInt(number, 10);
      if (letter === 'm') {
        totalDays = totalDays * 30; // 30 days per month
      }
      return totalDays;
    }
    return 999;
  });

  return {
    type: 'UPDATE_ARRIVAL_INFORMATION',
    payload: arrivalInformation,
    minDefaultValueTicketType,
    minMaxValueTicketType,
  };
});

export default {
  updateArrivalInformation,
};
