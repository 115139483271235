import { List } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import 'react-bootstrap-modal/lib/css/rbm-patch.css';
import 'react-day-picker/lib/style.css';
import { chunks, getTransformedDate } from '../../helpers/utils';
import widgetConfig from '../../widgetConfig';
import DatePickerSelector from '../datepicker/datepicker';
import FormField from '../form-field/form-field';

import { checkShouldShowRuleInTab, validateRequiredBillingContact } from '../validator/validator';

const MobileSelector = ({
  options,
  onChange,
  name,
  value,
}) => (
  <div className='groups'>
    {chunks(options, 7).map((group, id) => (
      <div key={id} className='btn-group'>
        {group.map((option) => {
          const isSelectedOption = (option.value === value);
          return (
            <button
              key={option.value}
              type='button'
              className={`btn ${isSelectedOption ? 'btn-success' : 'btn-default'}`}
              onClick={() => onChange(option.value, name)}
            >
              {option.name}
            </button>
          );
        })}
      </div>
    ))}
  </div>
);

MobileSelector.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
};

MobileSelector.defaultProps = {
  value: null,
};

const mapFields = [
  ['daysInPark', 'days_in_the_park'],
  ['departureDate', 'departure_date'],
];


export default class ArrivalAdditionForm extends React.Component {
  static propTypes = {
    arrivalInformation: PropTypes.object.isRequired,
    possibleDays: PropTypes.array.isRequired,
    billingValidationRules: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    fullWidthInMobile: PropTypes.bool,
  };

  static defaultProps = {
    fullWidthInMobile: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      showDepartureDatePicker: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { billingValidationRules, mobileView } = this.props;

    // Should clear field value when it disable
    mapFields.forEach(([fieldRule, fieldRuleApi]) => {
      if (!billingValidationRules[fieldRuleApi] && prevProps.billingValidationRules[fieldRuleApi]) {
        // Should not clear daysInPark in TouchMode Org
        if (!fieldRule === 'daysInPark' && mobileView) {
          this.onChange(null, fieldRule);
        }
      }
    });
  }

  onChange(value, property) {
    let data = this.props.arrivalInformation;
    if (property == 'daysInPark' && Number.isNaN(parseInt(value, 10))) {
      data = data.set(property, null);
    } else {
      data = data.set(property, value);
    }
    data = data.set(
      'billingValidationRules', this.props.billingValidationRules,
    );
    this.props.onChange(data);
  }

  getVisibleCateogry = () => {
    const { categoryId, categories } = this.props;
    return categoryId ?
      categories.find(category => category.get('id') == categoryId) :
      categories.get(0);
  };

  getClassWrapper = () => {
    const { fullWidthInMobile } = this.props;
    if (fullWidthInMobile) {
      return 'flex-1 addition-form-input';
    }
    return 'flex-1 basic-50';
  };

  checkFocus() {
    const { billingValidationRules, billingInformation, errors } = this.props;
    const fieldError = validateRequiredBillingContact(
      mapFields, billingInformation.toJS(), billingValidationRules, errors);
    if (fieldError && this[fieldError] && this[fieldError].focus) {
      this[fieldError].scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        this[fieldError].focus();
      }, 500);
      return true;
    }
    return false;
  }
  render() {
    const {
      arrivalInformation,
      billingValidationRules,
      mobileView,
      possibleDays,
      ticketTypes,
    } = this.props;

    // hide the datepicker after the date has been chosen
    // do not let the departure date be before arrival
    const onDepartureDateChange = (date) => {
      if (date) {
        let desiredDepartureDate = moment(date);
        const arrivalDate = arrivalInformation.get('arrivalDate');
        // if (Math.abs(desiredDepartureDate.diff(arrivalDate, 'days')) >= 366) {
        //   return false;
        // }
        const currentArrivalDate = moment(arrivalDate);
        if (!currentArrivalDate.isSameOrBefore(desiredDepartureDate)) {
          // TODO: here we reset the departure date. Should we use default
          // values, specific por park pass, instead of replacing it by the new arrival date?
          desiredDepartureDate = currentArrivalDate;
        }
        this.onChange(desiredDepartureDate, 'departureDate');
        // Use a separated redux state to store the user's selected date
        this.onChange(desiredDepartureDate, 'userSelectedDepartureDate');
      }
      this.setState({ showDepartureDatePicker: false });
    };
    const departureDate = arrivalInformation.get('departureDate');
    const arrivalDate = arrivalInformation.get('arrivalDate');
    // if (departureDate === null && billingValidationRules.departure_date !== null) {
    //   departureDate = arrivalDate;
    //   this.onChange(departureDate, 'departureDate');
    // }
    const visibleCategory = this.getVisibleCateogry();
    const ticketTypesByCategory = visibleCategory ? (
      ticketTypes.filter(
        ticketType => ticketType.get('category') === visibleCategory.get('id'),
      )
    ) : (
      new List()
    );
    const errors = arrivalInformation.get('errors');

    let showDaysInThePark = checkShouldShowRuleInTab(ticketTypesByCategory, 'days_in_the_park');
    if (widgetConfig.isMultiCategorySaleAllowed) {
      showDaysInThePark = billingValidationRules.days_in_the_park != null;
    }

    let showDepartureDate = checkShouldShowRuleInTab(ticketTypesByCategory, 'departure_date');
    if (widgetConfig.isMultiCategorySaleAllowed) {
      showDepartureDate = billingValidationRules.departure_date;
    }

    // set default max value for departure date
    let departureValidForMax = moment().add(366, 'days');
    const selectedTickets = ticketTypesByCategory.filter(ticketType => ticketType.get('count') > 0);

    // get min value of valid_for_max from the list of selected tickets
    const minValueTicketType = selectedTickets.minBy((ticketType) => {
      const valid_for_max = ticketType.get('valid_for_max');
      if (valid_for_max) {
        const [number, letter] = valid_for_max.match(/[a-z]+|[^a-z]+/gi);
        let totalDays = parseInt(number, 10);
        if (letter === 'm') {
          totalDays = totalDays * 30; // 30 days per month
        }
        return totalDays;
      }
      return 9999;
    });

    if (minValueTicketType) {
      const valid_for_max = minValueTicketType.get('valid_for_max');
      departureValidForMax = getTransformedDate(valid_for_max, moment(arrivalDate));
    }

    return (
      <React.Fragment>
        { showDaysInThePark && (
          <div className={this.getClassWrapper()}>
            <FormField
              refInput={(inputComponent) => { this.daysInPark = inputComponent; }}
              required={billingValidationRules.days_in_the_park}
              name='daysInPark'
              className='days-in-park'
              label={`How many days will you spend in the park?${billingValidationRules.days_in_the_park ? '*' : ''}`}
              placeholder='Select number of days'
              componentClass={mobileView ? MobileSelector : 'select'}
              options={possibleDays}
              value={arrivalInformation.get('daysInPark')}
              onChange={(...props) => this.onChange(...props)}
            />
          </div>
        ) }
        { showDepartureDate && (
          <div className={this.getClassWrapper()}>
            <FormField
              disabled={billingValidationRules.departure_date === null}
              refInput={(inputComponent) => { this.departureDate = inputComponent; }}
              required={billingValidationRules.departure_date !== null}
              readOnly='true'
              name='departureDate'
              label={`Departure date${billingValidationRules.departure_date !== null ? '*' : ''}`}
              prependIcon='fa fa-calendar'
              placeholder='Select Date'
              value={departureDate ? departureDate.format('DD MMMM, YYYY') : ''}
              onClick={() => this.setState({ showDepartureDatePicker: true })}
            />
          </div>
        ) }

        { errors && errors.size !== 0 &&
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
            <div className='alert alert-warning'>
              {
                errors.map(
                  (error, id) => (<p key={id}>{error.description}</p>),
                )
              }
            </div>
          </div>
        }

        <DatePickerSelector
          selectedDate={departureDate || moment()}
          show={this.state.showDepartureDatePicker}
          disabledDays={{
            before: arrivalDate ? new Date(arrivalDate) : new Date(),
            after: new Date(departureValidForMax),
          }}
          onChange={onDepartureDateChange}
        />
      </React.Fragment>
    );
  }
}
