import { List, fromJS } from 'immutable';


const categories = (state = new List(), action) => {
  switch (action.type) {
    case 'FETCH_CATEGORIES_COMPLETED':
      return state.merge(fromJS(action.payload.results));
    default:
      return state;
  }
};

export default categories;
