
const setCookie = (name, value, options) => {
  const opts = options || {};

  let { expires } = opts;

  if (typeof expires === 'number' && expires) {
    const d = new Date();
    d.setTime(d.getTime() + (expires * 1000));
    expires = d;
    opts.expires = expires;
  }
  if (expires && expires.toUTCString) {
    opts.expires = expires.toUTCString();
  }

  const encValue = encodeURIComponent(value);

  let updatedCookie = `${name}=${encValue}`;

  /* eslint-disable guard-for-in, no-restricted-syntax */
  for (const propName in opts) {
    updatedCookie = `${updatedCookie}; ${propName}`;
    const propValue = opts[propName];
    if (propValue !== true) {
      updatedCookie = `${updatedCookie}=${propValue}`;
    }
  }
  /* eslint-enable guard-for-in, no-restricted-syntax */

  document.cookie = updatedCookie;
};


const deleteCookie = (name) => {
  setCookie(name, '', {
    expires: -1,
  });
};


const getCookie = (cookieName) => {
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';').map((c) => {
      const [name, value] = c.trim().split('=');
      return {
        name,
        value,
      };
    });

    const cookie = cookies.find(c => c.name === cookieName);
    if (cookie) {
      return decodeURIComponent(cookie.value);
    }
  }
  return null;
};


export default {
  set: setCookie,
  remove: deleteCookie,
  get: getCookie,
};
