import { List } from 'immutable';
// import { isValidNumber } from 'libphonenumber-js';
import moment from 'moment';

import widgetConfig from '../../widgetConfig';


class ValidationError {
  constructor(message) {
    this.message = message;
  }
}


export default class BaseValidator {
  constructor(validate_function, message) {
    this.validate_function = validate_function;
    this.message = message;
  }

  validate(value) {
    if (!this.validate_function(value)) {
      throw new ValidationError(this.message);
    }
  }
}


class MinValueValidator extends BaseValidator {
  constructor(min_value, message) {
    super(
      value => value >= min_value,
      message || `Ensure this value is greater than or equal to ${min_value}`,
    );
  }
}


class MaxValueValidator extends BaseValidator {
  constructor(max_value, message) {
    super(
      value => value <= max_value,
      message || `Ensure this value is less than or equal to ${max_value}`,
    );
  }
}


class MinLengthValueValidator extends BaseValidator {
  constructor(length, message) {
    super(
      value => value.toString().trim().length >= length,
      message || `Ensure this value has at least ${length} characters`,
    );
  }
}


class MaxLengthValueValidator extends BaseValidator {
  constructor(length, message) {
    super(
      value => value.toString().trim().length <= length,
      message || `Ensure this value has at most ${length} characters`,
    );
  }
}


class EmailValidator extends BaseValidator {
  constructor(message) {
    super(
      value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message || 'Enter a valid email address',
    );
  }
}

class NumberValidator extends BaseValidator {
  constructor(message) {
    super(
      value => /^\d+$/.test(value),
      message || 'Ensure that value contains only numbers',
    );
  }
}

class NTPostcodeValidator extends BaseValidator {
  constructor(message) {
    super(
      value => /^(08[0-9][0-9]|09[0-9][0-9]|4825)$/.test(value),
      message || 'The postcode must be your valid residential postcode in the Northern Territory',
    );
  }
}


function cummulative_rule(v1, v2) {
  if (v1 === null) {
    return v2;
  }
  if (v2 === null) {
    return v1;
  }
  return v1 || v2;
}

function checkShouldShowRuleInTab (ticketTypesByCategory, field) {
  const exists = Boolean(ticketTypesByCategory.find((tt) => {
    const ttRules = tt.toJS().validation_rules;
    if (ttRules && ttRules.general && ttRules.general && ttRules.general[field]) {
      return true;
    }
    return false;
  }));
  return exists;
}

function getExtendedValidationRules(ticketTypes, widgetType, country, fullDiscountInEffect) {
  /*
  Return validation rules for the BillingContact section
  If ticket types have their own validation_rules field filled then get it from there,
  otherwise - default, based on isRetail, isAustraliaCountry and so on
  */
  let isExtendedValidation = true;
  let rules = {
    days_in_the_park: null,
    departure_date: null,
    email: false,
    first_last_name: false,
    country: false,
    postcode: false,
    phone: false,
    rego: null,
    company: null,
    purpose: null,
    document_upload_required: false,
    visitors: null,
  };

  ticketTypes.forEach((tt) => {
    if (tt.get('count')) {
      // only choosen pass types influence the validation rules
      const ttRules = tt.toJS().validation_rules;
      if (!ttRules) {
        isExtendedValidation = false;
      } else {
        isExtendedValidation = true;
        Object.keys(rules).forEach((key) => {
          if (key === 'days_in_the_park') {
            rules[key] = cummulative_rule(rules[key], ttRules.general[key]);
          } else if (key === 'departure_date') {
            rules[key] = cummulative_rule(rules[key], ttRules.general[key]);
          } else if (key === 'document_upload_required') {
            rules[key] = cummulative_rule(rules[key], tt.get('document_upload_required'));
          } else if (typeof ttRules.billing_contact[key] === 'string') {
            // static value arrives
            rules[key] = ttRules.billing_contact[key];
          } else if (typeof rules[key] !== 'string') {
            // previous value is boolen, so no string winners yet
            rules[key] = cummulative_rule(rules[key], ttRules.billing_contact[key]);
          }
        });
      }
    }
  });

  if (!isExtendedValidation) {
    // legacy rules, as were defined before, constant
    rules = {
      days_in_the_park: true,
      departure_date: null,
      email: widgetType === 'retail',
      first_last_name: true,
      country: true,
      postcode: country === 'AU',
      phone: false,
      rego: null,
      company: null,
      purpose: null,
      document_upload_required: false,
      visitors: null,
    };
  }

  if (!ticketTypes.some(v => v.get('count', 0) > 0)) {
    rules.email = false;
    rules.first_last_name = false;
    rules.country = false;
    rules.postcode = false;
    rules.phone = false;
    rules.rego = null;
    rules.company = false;
    rules.purpose = false;
    rules.document_upload_required = false;
    rules.visitors = false;
  }

  if (rules.departure_date && !rules.days_in_the_park) {
    rules.days_in_the_park = null;
  } else if (rules.days_in_the_park && !rules.departure_date) {
    rules.departure_date = null;
  } else if (rules.days_in_the_park && rules.departure_date) {
    // both fields can't be displayed, so smaller one wins
    rules.days_in_the_park = true;
    rules.departure_date = null;
  }
  rules.client_payment_method = !fullDiscountInEffect;
  return rules;
}

function getTicketTypeValidationRules(ticketType) {
  const newValidationRules = ticketType.toJS().validation_rules;
  const assignPassRules = newValidationRules.assign_pass;

  const rules = Object.assign(
    { document_upload_required: ticketType.get('document_upload_required') },
    assignPassRules,
  );
  return rules;
}

function validateRequiredFields(rules, arrivalInformation, billingContact) {
  const fields = new Map([
    ['firstName', 'first_last_name'],
    ['lastName', 'first_last_name'],
    ['country', 'country'],
    ['daysInPark', 'days_in_the_park'],
    ['phone', 'phone'],
    ['vehicle', 'rego'],
    ['email', 'email'],
    ['company', 'company'],
    ['purpose', 'purpose'],
    ['visitors', 'visitors'],
  ]);
  let requiredFields = [
    'agreement', 'arrivalDate', // always
  ];
  // eslint-disable-next-line no-restricted-syntax
  for (const [jsFieldName, apiFieldName] of fields) {
    if (rules[apiFieldName]) { // TODO: === true
      requiredFields.push(jsFieldName);
    }
  }
  [arrivalInformation, billingContact].forEach((mapObj) => {
    mapObj.forEach((value, key) => {
      if (!!value && value.toString().trim()) {
        requiredFields = requiredFields.filter(item => item !== key);
      }
    });
  });
  return requiredFields.length === 0;
}

function validatePassRequiredFields(ticket) {
  const fields = new Map([
    ['firstName', 'first_last_name'],
    ['lastName', 'first_last_name'],
    ['country', 'country'],
    ['daysInPark', 'days_in_the_park'],
    ['phone', 'phone'],
    ['vehicle', 'rego'],
    ['email', 'email'],
  ]);
  const requiredFields = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [jsFieldName, apiFieldName] of fields) {
    if (ticket.ticketValidationRules[apiFieldName]) { // TODO: === true
      if (!ticket[jsFieldName] || !ticket[jsFieldName].toString().trim()) {
        requiredFields.push(jsFieldName);
      }
    }
  }
  return requiredFields.length === 0;
}

function validateFindRequiredFields(ticket, errors) {
  const fields = new Map([
    ['firstName', 'first_last_name'],
    ['lastName', 'first_last_name'],
    ['country', 'country'],
    ['daysInPark', 'days_in_the_park'],
    ['phone', 'phone'],
    ['vehicle', 'rego'],
    ['email', 'email'],
  ]);
  const errorsFields = errors.map(e => e.code);
  if (errorsFields.indexOf('postcode') !== -1) return 'postcode';
  // const requiredFields = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [jsFieldName, apiFieldName] of fields) {
    if (errorsFields.indexOf(jsFieldName) !== -1) return jsFieldName;
    if (ticket.ticketValidationRules[apiFieldName]) { // TODO: === true
      if (!ticket[jsFieldName] || !ticket[jsFieldName].toString().trim()) {
        return jsFieldName;
      }
    }
  }
  return '';
}

function validateRequiredBillingContact(fieldsMap, billingContact, rules, errors) {
  const errorsFields = errors.map(e => e.code);
  if (errorsFields.indexOf('postcode') !== -1) return 'postcode';
  const fields = new Map(fieldsMap);
  // eslint-disable-next-line no-restricted-syntax
  for (const [jsFieldName, apiFieldName] of fields) {
    if (errorsFields.indexOf(jsFieldName) !== -1) return jsFieldName;
    if (rules[apiFieldName]) { // TODO: === true
      if (!billingContact[jsFieldName] || !billingContact[jsFieldName].toString().trim()) {
        return jsFieldName;
      }
    }
  }
  return '';
}

function validateBillingContact(payload, rules, action, id) {
  /*
  Returns either empty List() (if no errors) or List() of errors with messages to the user
  If it's empty then no validation problems were found
  */

  let postcodePattern = /^\d{4}$/;

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let newState = new List();
  // postcode: either required or some value present,
  // so (no value and no requirement) or (the correct value) is accepted
  if (rules.postcode || payload.postcode) {
    if (typeof rules.postcode === 'string') {
      /**
       * If postcode validation field is "nt" text then
       * value must be between 0800 and 0999 (but still 4 digits) or 4825
       * */
      const NTPostcodePatt = /nt/i;
      if (NTPostcodePatt.test(rules.postcode)) {
        postcodePattern = /^(08[0-9][0-9]|09[0-9][0-9]|4825)$/;
        if (!postcodePattern.test(payload.postcode)) {
          newState = newState.push({
            id,
            code: 'postcode',
            description: 'The postcode must be your valid residential postcode in the Northern Territory',
          });
        }
      } else if (rules.postcode !== payload.postcode) {
        newState = newState.push({
          id,
          code: 'postcode',
          description: `The postcode is required to be exactly ${rules.postcode}`,
        });
      }
    } else if (payload.country === 'AU' && !postcodePattern.test(payload.postcode)) {
      newState = newState.push({
        id,
        code: 'postcode',
        description: '4-digit postcode is required for Australia',
      });
    }
  }

  if (rules.first_last_name) {
    if (payload.firstName != null &&
        payload.firstName !== '' &&
        payload.firstName.length < 1) {
      newState = newState.push({
        id,
        code: 'firstName',
        description:
          `First name should contain at least 1 character`,
      });
    }
    if (payload.lastName != null &&
        payload.lastName !== '' &&
        payload.lastName.length < 2) {
      newState = newState.push({
        id,
        code: 'lastName',
        description:
          `Last name should contain at least 2 characters`,
      });
    }
  }

  // if (action === 'UPDATE_BILLING_INFORMATION' &&
  //     payload.phone &&
  //   (payload.phone.length > 0 && !isValidNumber(payload.phone))) {
  //   newState = newState.push({
  //     id,
  //     code: 'phone',
  //     description: 'Invalid phone number'
  //   });
  // }

  if (payload.vehicle && payload.vehicle.length > 15) {
    newState = newState.push({
      id,
      code: 'vehicle',
      description: 'Vehicle number must be less than equal 15 characters long',
    });
  }

  if (payload.email != null &&
      payload.email !== '' &&
      !emailPattern.test(payload.email)) {
    newState = newState.push({
      id,
      code: 'email',
      description: 'Enter a valid email address',
    });
  }

  if (!payload.agreement && action === 'UPDATE_BILLING_INFORMATION') {
    newState = newState.push({
      id,
      code: 'agreement',
      description: 'You must agree with the terms to proceed',
    });
  }

  if (action === 'UPDATE_BILLING_INFORMATION' && widgetConfig.widgetType === 'retail') {
    if (rules.document_upload_required && !payload.identification_sighted) {
      newState = newState.push({
        id,
        code: 'identification_sighted',
        description: 'You must ensure that the identification is sighted and valid',
      });
    }
  }

  // payment method is required for Parks
  if (action === 'UPDATE_BILLING_INFORMATION') {
    if (widgetConfig.widgetType === 'retail'
        && widgetConfig.retailerType === 'park'
        && rules.client_payment_method) {
      if (!payload.clientPaymentMethod) {
        newState = newState.push({
          id,
          code: 'clientPaymentMethod',
          description: 'Please select a client payment method',
        });
      }
    }
  }

  return newState;
}


function validateArrivalContact(arrivalInformation) {
  /*
  Returns either empty List() (if no errors) or List() of errors with messages to the user
  If it's empty then no validation problems were found
  */
  let errors = new List();
  const rules = arrivalInformation.get('billingValidationRules');

  if (rules.departure_date) {
    const arrival = moment(arrivalInformation.get('arrivalDate'));
    const departure = moment(arrivalInformation.get('departureDate'));
    if (!arrival.isSameOrBefore(departure)) {
      errors = errors.push({
        code: 'departureDate',
        description: 'Departure date must be after the arrival',
      });
    }
  }
  return errors;
}

function performNisCheck(ticketTypes) {
  /*
  Return True if selected ticket types can't be sold due to only NIS tickets selected
  */
  let hasNormalTickets = false;
  let hasNisTickets = false;
  ticketTypes.forEach((tt) => {
    if (tt.get('count')) {
      if (tt.get('not_for_individual_sale')) {
        hasNisTickets = true;
      } else {
        hasNormalTickets = true;
      }
    }
  });
  return !hasNisTickets || hasNormalTickets;
}

export {
  ValidationError,
  MinValueValidator,
  MaxValueValidator,
  MinLengthValueValidator,
  MaxLengthValueValidator,
  EmailValidator,
  NumberValidator,
  getExtendedValidationRules,
  validateRequiredFields,
  getTicketTypeValidationRules,
  validatePassRequiredFields,
  validateBillingContact,
  validateArrivalContact,
  performNisCheck,
  validateFindRequiredFields,
  validateRequiredBillingContact,
  checkShouldShowRuleInTab,
  NTPostcodeValidator,
};
