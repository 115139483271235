import PropTypes from 'prop-types';
import React from 'react';

import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';


const OverlayTooltip = ({ tooltip, id, icon }) => {
  const tooltipComponent = <Tooltip id={id}>{tooltip}</Tooltip>;
  return (
    <OverlayTrigger trigger={['click', 'hover', 'focus']} placement='top' overlay={tooltipComponent}>
      {icon}
    </OverlayTrigger>
  );
};


OverlayTooltip.propTypes = {
  tooltip: PropTypes.node.isRequired,
  id: PropTypes.node.isRequired,
  icon: PropTypes.node,
};

OverlayTooltip.defaultProps = {
  icon: (<i className='fa fa-question-circle' />),
};

export default OverlayTooltip;
