import { fromJS } from 'immutable';


const seasons = (state = null, action) => {
  switch (action.type) {
    case 'SET_PARK':
      return null;
    case 'FETCH_SEASONS_COMPLETED':
      return fromJS(action.payload.results);
    default:
      return state;
  }
};

export default seasons;
