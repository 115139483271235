import PropTypes from 'prop-types';
import React from 'react';
import FormField from '../form-field/form-field';
import OverlayTooltip from '../overlay-tooltip';
import FormattedPrice from '../formatted-price/formatted-price';

export default class MerchGridItem extends React.Component {
  static propTypes = {
    merchItem: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isEnabled: PropTypes.bool,
  };

  static defaultProps = {
    isEnabled: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      showVariants: false,
    };
  }

  updateQty(variant, qty) {
    let { merchItem } = this.props;

    if (merchItem === variant) {
      merchItem = merchItem.set('qty', Math.min(qty, merchItem.get('stock')));
    } else {
      merchItem = merchItem.update('variants', variants =>
        variants.map((v) => (v === variant ? v.set('qty', Math.min(qty, v.get('stock'))) : v)));
    }

    this.props.onChange(merchItem);
  }

  toggleVariants() {
    this.setState((state) => ({ showVariants: !state.showVariants }));
  }

  renderQty = (variant) => {
    const { merchItem, isEnabled, mobileView } = this.props;
    const qty = variant.get('qty', 0);
    const stock = variant.get('stock');

    return (
      <div className='number-ticket'>
        <div className='input-group'>
          <div className='input-group-btn'>
            <button
              className={`btn btn-default ${mobileView && 'btn-lg'}`}
              disabled={qty === 0}
              aria-label={`decrement ${merchItem.get('name')} quantity`}
              onClick={() => this.updateQty(variant, qty - 1)}
            >
              <i className='fa fa-minus' aria-hidden='true' />
            </button>
          </div>
          <FormField
            aria-label={`quantity for ${merchItem.get('name')}, up to ${stock}`}
            type='number' name={merchItem.get('name')}
            min={0} max={stock}
            value={qty.toString()}
            title={`Up to ${stock}`}
            disabled={!isEnabled}
            className={`${mobileView && 'input-lg'}`}
            onChange={value => this.updateQty(variant, window.parseInt(value))}
          />
          <div className='input-group-btn'>
            <button
              className={`btn btn-default ${mobileView && 'btn-lg'}`}
              disabled={qty >= stock}
              title={`Up to ${stock}`}
              aria-label={`increment ${merchItem.get('name')} quantity`}
              onClick={() => this.updateQty(variant, qty + 1)}
            >
              <i className='fa fa-plus' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderPriceAndQty = (variants, isSmall) => {
    const { merchItem, mobileView } = this.props;

    if (variants.size > 0) {
      const selectedQty = variants.reduce((acc, curr) => acc + curr.get('qty', 0), 0);

      return (
        <>
          <button
            aria-label="show variants"
            className={`btn btn-default ${mobileView && 'btn-lg'}`}
            onClick={() => this.toggleVariants()}
          >
            {selectedQty > 0 ? `${selectedQty} selected` : 'select variant'}
          </button>
          <div className={`grid-variants ${this.state.showVariants ? 'visible' : 'hidden'}`}>
            <div className="variants-content">
              <h4>
                <strong>{merchItem.get('name')}</strong> — <FormattedPrice value={merchItem.get('price')} />
              </h4>
              <div className="variants-grid">
              {variants.map((variant, index) => (
                <div key={index} className="variants-grid-item">
                  <strong>{variant.get('name')}</strong>
                  <div className={`ticket-qty ${mobileView && 'touch-screen-mode'}`}>
                    {this.renderQty(variant)}
                  </div>
                </div>
              ))}
              </div>
              <div className="variants-footer">
                <button
                  aria-label="close"
                  className={`btn ${mobileView && 'btn-lg'}`}
                  onClick={() => this.toggleVariants()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className={`ticket-qty ${mobileView && 'touch-screen-mode'}`}>
        {this.renderQty(merchItem)}
      </div>
    );
  }

  render() {
    const { merchItem } = this.props;
    const description = merchItem.get('short_description');
    const variants = merchItem.get('variants');

    return (
      <div className="merch-grid-item">
        <div className='description-text'>
          <strong className='merch-name'>
            <span>{merchItem.get('name')}</span>
            {description && (
              <OverlayTooltip tooltip={description} id={`description_${merchItem.get('id')}`} />
            )}
          </strong>
          <FormattedPrice value={merchItem.get('price')} />
        </div>
        {this.renderPriceAndQty(variants)}
      </div>
    );
  }
}
