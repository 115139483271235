import { createThunkAction } from '../helpers';
import request from '../request';
import widgetConfig from '../widgetConfig';

const fetchSeasons = createThunkAction('FETCH_SEASONS', (parkId, date) => {
  if (!date) {
    return null;
  }
  return request(`/api/frapi/v0/parks/${parkId}/seasons/?start_date=${
    date.format('YYYY-MM-DD')
  }&${widgetConfig.getAuthQuerystring()}`);
});

export default {
  fetchSeasons,
};
