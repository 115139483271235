import { List, fromJS } from 'immutable';

const merch = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_MERCH_COMPLETED':
      return new List(
        fromJS(
          action.payload.results
            .map(v => ({ ...v, variants: v.variants.sort(x => x.order) }))
            .sort(v => v.order),
        ),
      );
    case 'UPDATE_MERCH_ITEM':
      return state.update(
        state.findIndex(item => (item.get('id') === action.payload.get('id'))),
        item => item.merge(action.payload),
      );
    default:
      return state;
  }
};

export default merch;
