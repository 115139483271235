import React from 'react';
import PropTypes from 'prop-types';
import { dateFormat } from '../../helpers';


const SeasonInformation = ({ season }) => {
  const formatter = 'MMMM YYYY';
  return (
    <div className='season'>
      <h2 className='text-center'>
        {season.get('name')}
      </h2>
      <p className='text-center'>
        {dateFormat(season.get('start_date'), formatter)}
        &nbsp;-&nbsp;
        {dateFormat(season.get('end_date'), formatter)}
      </p>
    </div>
  );
};

SeasonInformation.propTypes = {
  season: PropTypes.object.isRequired,
};


export default SeasonInformation;
