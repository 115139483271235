import { createSimpleAction } from '../helpers';


const resetErrors = createSimpleAction(() => ({
  type: 'RESET_ERRORS',
  payload: {},
}));

const raiseInvalidStartDateError = createSimpleAction(() => ({
  type: 'INVALID_START_DATE',
  payload: { error: 'Invalid Start date' },
}));

export default {
  resetErrors,
  raiseInvalidStartDateError,
};
