import { List, fromJS } from 'immutable';


const countries = (state = new List(), action) => {
  switch (action.type) {
    case 'FETCH_COUNTRIES_COMPLETED':
      return state.merge(fromJS(action.payload));
    default:
      return state;
  }
};

export default countries;
