import { createThunkAction, createSimpleAction } from '../helpers';
import request from '../request';
import widgetConfig from '../widgetConfig';

const fetchParks = createThunkAction('FETCH_PARKS', () => request(
  `/api/frapi/v0/parks/?${widgetConfig.getAuthQuerystring()}`,
));


const setPark = createSimpleAction(park => ({
  type: 'SET_PARK',
  payload: {
    park,
  },
}));

export default {
  fetchParks,
  setPark,
};
