// TODO: immutable after init
// eslint-disable-next-line import/no-mutable-exports
let widgetConfig = {};
if (Object.prototype.hasOwnProperty.call(window, 'pos_widget_configuration')) {
  widgetConfig = Object.assign({}, window.pos_widget_configuration);
} else {
  widgetConfig = {
    fullDiscountSince: null,
    fullDiscountTill: null,
    org_id: null,
    widgetType: 'fit',
    retailerType: 'fit',
    recaptachaKey: 'set-for-catpcha-debug',
    mobileView: false,
    gridView: false,
    maxTicketCount: 100,
    isMultiCategorySaleAllowed: false,
    showOrderConfirmationPageFit: false,
    showOrderConfirmationPageRetail: false,
    showOrderConfirmationPageParks: false,
    showOrderConfirmationPageCTO: false,
    showCommbankPaymentMethod: false,
    showCreditPaymentMethod: false,
    showMerchSection: true,
  };
}

// To use widget as a park organisation with `yarn start` uncomment next code
// and fill values exactly like you see on the page source where the widget is configured
// (like https://integration.ecommerce.np.cp1.parksaustralia.gov.au/15/passes/uluru/)
// apart of token, which you take for this org from,
// and org_id, which you leave empty while using the bearer token
// /admin/organisations/organisationoauth2token/?org__id__exact=15  - change ORG id here
// (but don't forget to return it back and do not commit your uncommented version)
// widgetConfig.widgetType = 'retail'
// widgetConfig.retailerType = "park"
// widgetConfig.bearerToken = "..."

if (widgetConfig.org_id) {
  widgetConfig.url_prefix = `/${widgetConfig.org_id}`;
  widgetConfig.widgetType = 'retail';
} else {
  widgetConfig.org_id = null;
  widgetConfig.url_prefix = '';
}

if (widgetConfig.retailerType == 'park') {
  widgetConfig.isMultiCategorySaleAllowed = true;
} else {
  widgetConfig.isMultiCategorySaleAllowed = false;
}


// DEBUG
// widgetConfig.isMultiCategorySaleAllowed = true;

widgetConfig.orderConfirmationPage = (
  widgetConfig.widgetType === 'fit' && widgetConfig.showOrderConfirmationPageFit
) || (
  widgetConfig.widgetType === 'retail' && widgetConfig.showOrderConfirmationPageRetail
) || (
  widgetConfig.widgetType === 'retail' && widgetConfig.showOrderConfirmationPageCTO
) || (
  widgetConfig.widgetType === 'retail' && widgetConfig.showOrderConfirmationPageParks
);

function getParameterByName(name, url) {
  /* eslint-disable no-param-reassign */
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  /* eslint-enable no-param-reassign */
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function getAuthQuerystring() {
  const org = widgetConfig.org_id;
  const isEntryStation = widgetConfig.mobileView;

  // const orgQuery = `?org=${org}`;
  const params = {};
  if (org) {
    params.org = org;
  }
  if (isEntryStation) {
    params.is_entrystation = 'true';
  }

  const query = Object.keys(params).map(
    k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`,
  ).join('&');
  return query;
}

getAuthQuerystring();

widgetConfig.getAuthQuerystring = getAuthQuerystring;

// 'business' for showing only business passes, 'non-business' to show anything except them
// 'both' for both - allowed and forced only for parks itself.
widgetConfig.salesType = (
  getParameterByName('sales') || 'non-business'
).toLowerCase().trim();
if (widgetConfig.salesType !== 'business' && widgetConfig.salesType !== 'non-business') {
  widgetConfig.salesType = 'non-business';
}

if (widgetConfig.retailerType === 'park') {
  widgetConfig.salesType = 'both';
}

export default widgetConfig;
