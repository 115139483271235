import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory as createHistory } from 'history';
import { Provider } from 'react-redux';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import configureStore from './store';
import { ChoosePark, CreateParty, AssignPasses } from './containers';
import Cookies from './helpers/cookies';
import widgetConfig from './widgetConfig';
import request from './request';
import './pos-widget-v2.css';
import './select-css.css';
import './select-css-compat.css';
import './select-css-arrow.css';
import './park_css/base_theme.css';

// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory();
const store = configureStore(history);

if (!window.history) {
  window.addEventListener('hashchange', () => {
    const backUrl = Cookies.get('backUrl');
    if (backUrl) {
      Cookies.remove('backUrl');
      window.location.href = backUrl;
    }
  });
}

const ScrollToTop = () => {
  // hacky router to ensure window gets scrolled up every time
  window.scrollTo(0, 0);
  return null;
};


// Do some simple request every 10 minutes so auth doesn't expire
// (for installations with small auth timeout)
function periodical_auth_request() {
  request(
    `/api/frapi/v0/parks/?${widgetConfig.getAuthQuerystring()}`,
  );
}
setInterval(periodical_auth_request, 10 * 60 * 1000);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <React.Fragment>
        <Route component={ScrollToTop} />
        <Switch>
          <Route
            path={`${widgetConfig.url_prefix}/passes/assign-passes/`}
            component={AssignPasses}
          />
          <Route
            path={`${widgetConfig.url_prefix}/passes/:parkSlug/category/:categoryId/`}
            component={CreateParty}
          />
          <Route
            path={`${widgetConfig.url_prefix}/passes/:parkSlug/`}
            component={CreateParty}
          />
          <Route
            path={`${widgetConfig.url_prefix}/passes/`}
            component={ChoosePark}
          />
          <Redirect
            from='/'
            to={`${widgetConfig.url_prefix}/passes/`}
          />
        </Switch>
      </React.Fragment>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
