import { fromJS, Map } from 'immutable';
import { isValidNumber } from 'libphonenumber-js';
import { getExtendedValidationRules } from '../components/validator/validator';

import widgetConfig from '../widgetConfig';

const defaultState = new Map({
  email: '',
  firstName: '',
  clientPaymentMethod: null,
  lastName: '',
  country: 'AU',
  postcode: '',
  phoneCountry: 'AU',
  phone: '',
  phoneValid: true,
  vehicle: '',
  visitors: '',
  billingValidationRules: getExtendedValidationRules(
    [], widgetConfig.widgetType, 'AU', false,
  ),
  totalAmount: 0,
  totalDiscount: 0,
  totalTicketsAmount: 0,
  totalTicketsQty: 0,
  totalMerchAmount: 0,
  totalMerchQty: 0,
  subscribe: false,
  receive_sms: true,
  agreement: widgetConfig.mobileView || widgetConfig.widgetType === 'retail',
  identification_sighted: false,
});


const handlePostcode = (billingContact) => {
  if (
    billingContact &&
    billingContact.get('country') !== 'AU' &&
    billingContact.get('postcode') !== ''
  ) {
    return billingContact.set('postcode', '');
  }

  if (billingContact.get('phone')) {
    const phone = billingContact.get('phone');
    return billingContact.set('phoneValid', isValidNumber(phone));
  }
  return billingContact;
};


const billingContact = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_PARK':
    case 'RESET_BILLING_TOTALS':
    case 'FETCH_TICKET_TYPES_COMPLETED':
      return state.merge(fromJS({
        totalAmount: 0,
        totalDiscount: 0,
        totalTicketsAmount: 0,
        totalTicketsQty: 0,
        totalMerchAmount: 0,
        totalMerchQty: 0,
      }));
    case 'UPDATE_BILLING_INFORMATION':
      return handlePostcode(action.payload);
    case 'UPDATE_TICKET_COST_INFORMATION':
      return handlePostcode(state.merge(fromJS(action.payload)));
    case 'POST_PARTY_COMPLETED':
      if (widgetConfig.retailerType === 'park') {
        return defaultState;
      }
      return state;
    default:
      return state;
  }
};

export default billingContact;
