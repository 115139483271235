import { createSimpleAction, createThunkAction } from '../helpers';
import request from '../request';
import widgetConfig from '../widgetConfig';

const prepareParty = createSimpleAction(party => ({
  type: 'PREPARE_PARTY',
  payload: party,
}));

const updatePartyTicket = createSimpleAction(({ id, ticket }) => ({
  type: 'UPDATE_TICKET',
  payload: {
    id,
    ticket,
  },
}));

const postParty = createThunkAction('POST_PARTY', (party) => {
  const contactData = party.get('contactData');
  const cashTendered = party.get('cashTendered') ? party.get('cashTendered').toFixed(2) : null;

  const partyJSON = {
    season_id: party.get('season'),
    email: contactData.get('email'),
    email_subscribe: contactData.get('subscribe'),
    receive_sms: contactData.get('receive_sms'),
    payment_method: party.get('paymentMethod'),
    first_name: contactData.get('firstName'),
    last_name: contactData.get('lastName'),
    country: contactData.get('country'),
    postcode: contactData.get('postcode'),
    phone: contactData.get('phone'),
    vehicle: contactData.get('vehicle'),
    company: contactData.get('company'),
    purpose: contactData.get('purpose'),
    arrival_date: party.get('arrivalDate').format('YYYY-MM-DD'),
    days_in_the_park: party.get('daysInPark'),
    // quick_purchase_process: party.get('isPrintPartyManifest'),
    // for parks it's always quick.
    // Difference only in party being printed or not
    quick_purchase_process: widgetConfig.retailerType === 'park',
    visitors: contactData.get('visitors') || null,
  };

  // as requested by https://github.com/envris/nationalparks/issues/3261#issuecomment-455077367
  if (cashTendered) {
    partyJSON.cash_tendered = cashTendered;
  }
  if (party.get('tickets').size) {
    partyJSON.passes = party.get('tickets')
      .groupBy(t => t.get('passTypeId'))
      .map((tickets, key) => (
        {
          pass_type_id: key,
          count: tickets.size,
          contact_data: tickets.map(ticket => ({
            first_name: ticket.get('firstName'),
            last_name: ticket.get('lastName'),
            country: ticket.get('country'),
            phone: ticket.get('phone'),
            postcode: ticket.get('postcode'),
            vehicle: ticket.get('vehicle'),
            email: ticket.get('email'),
            email_subscribe: ticket.get('subscribe'),
          })).toJS(),
        }
      ))
      .toList()
      .toJS();
  }
  if (party.get('merch').size) {
    const merch = party.get('merch')
      .flatMap((merchItem) => {
        const merchId = merchItem.get('id');
        const merchQty = merchItem.get('qty', 0);
        if (merchQty > 0) {
          return [{
            merch_id: merchId,
            qty: merchQty,
          }];
        }
        return merchItem.get('variants').filter((v) => v.get('qty', 0) > 0).map(v => ({
          merch_id: merchId,
          variant_id: v.get('id'),
          qty: v.get('qty'),
        }));
      })
      .toJSON();
    if (merch.length) {
      partyJSON.merch = merch;
    }
  }
  let request_body = partyJSON;
  if (contactData.get('clientPaymentMethod')) {
    request_body = Object.assign(
      request_body,
      {
        client_payment_method: contactData.get('clientPaymentMethod'),
      },
    );
  }

  if (party.has('recaptchaResponse')) {
    request_body = Object.assign(
      request_body,
      {
        g_recaptcha_response: party.get('recaptchaResponse'),
      },
    );
  }

  const partyCreationUrl = `/api/frapi/v0/party/?${widgetConfig.getAuthQuerystring()}`;
  return request(partyCreationUrl, {
    method: 'POST',
    body: JSON.stringify(request_body),
  });
});

export default {
  prepareParty,
  updatePartyTicket,
  postParty,
};
