import { List } from 'immutable';

import { validateBillingContact } from '../components/validator/validator';

const errors = (state = new List(), action) => {
  switch (action.type) {
    case 'UPDATE_BILLING_INFORMATION': // from billing contacts step
    case 'UPDATE_TICKET': // for any particular ticket on "Assign Passes" page
      /*
      Receive: billing contact either from the billing contact for or from the pass
      assign form, in both cases it's the simularly looking object with postcode,
      firstname, lastname, country, ... fields.
      */
      const payload = action.payload.toJS ? action.payload.toJS() : action.payload.ticket.toJS();
      const { billingValidationRules, ticketValidationRules } = payload;
      const id = action.payload.id != null ? action.payload.id : -1;

      const rules = ticketValidationRules || billingValidationRules;
      const filteredErrors = state.filter(error => error.id !== id);

      return filteredErrors.concat(
        validateBillingContact(payload, rules, action.type, id),
      );
    case 'RESET_ERRORS':
      return new List();
    default:
      return state;
  }
};

export default errors;
