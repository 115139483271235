import { createSimpleAction } from '../helpers';

const updateTicketType = createSimpleAction(ticketType => ({
  type: 'UPDATE_TICKET_TYPE',
  payload: ticketType,
}));

export default {
  updateTicketType,
};
