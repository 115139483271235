import { Map } from 'immutable';
import moment from 'moment';

import { validateArrivalContact } from '../components/validator/validator';
import { getTransformedDate, today } from '../helpers/utils';
import widgetConfig from '../widgetConfig';

const getDefaultArrivalDate = () => {
  if (!widgetConfig.org_id && !widgetConfig.bearerToken) {
    return null;
  }
  return today();
};

const getFullDiscountInEffect = (arrivalDate) => {
  if (!arrivalDate) {
    return false;
  }

  const fullDiscountSince = widgetConfig.fullDiscountSince
    ? moment(widgetConfig.fullDiscountSince) : moment.invalid();
  const fullDiscountTill = widgetConfig.fullDiscountTill
    ? moment(widgetConfig.fullDiscountTill) : moment.invalid();

  return (fullDiscountSince.isValid() || fullDiscountTill.isValid())
    && (!fullDiscountSince.isValid() || fullDiscountSince.isSameOrBefore(arrivalDate, 'day'))
    && (!fullDiscountTill.isValid() || fullDiscountTill.isSameOrAfter(arrivalDate, 'day'));
};

const defaultArrivalDate = getDefaultArrivalDate();

const defaultState = new Map({
  arrivalDate: defaultArrivalDate,
  fullDiscountInEffect: getFullDiscountInEffect(defaultArrivalDate),
  departureDate: null,
  daysInPark: widgetConfig.mobileView ? 3 : null,
  userSelectedDepartureDate: null,
});

const arrivalInformation = (state = defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_ARRIVAL_INFORMATION':
      let { payload } = action;
      const { minDefaultValueTicketType, minMaxValueTicketType } = action;
      const rules = payload.get('billingValidationRules');
      const departureDate = payload.get('departureDate');
      const oldDepartureDate = state.get('departureDate');
      const arrivalDate = payload.get('arrivalDate');
      const userSelectedDepartureDate = payload.get('userSelectedDepartureDate');

      const isRenewDepartureDate = () => {
        if (rules.departure_date !== null) {
          if (userSelectedDepartureDate) {
            return false;
          }

          if (departureDate === null) {
            return true;
          }

          if (departureDate.diff(oldDepartureDate) === 0) {
            return true;
          }

          if (departureDate.diff(arrivalDate, 'days') < 0) {
            return true;
          }

          if (minDefaultValueTicketType) {
            const valid_for_default = minDefaultValueTicketType.get('valid_for_default');
            if (valid_for_default) {
              return true;
            }
          }
        }
        return false;
      };
      if (isRenewDepartureDate()) {
        if (minDefaultValueTicketType) {
          const valid_for_default = minDefaultValueTicketType.get('valid_for_default');
          const departureValidForDefault = getTransformedDate(
            valid_for_default, moment(arrivalDate),
          );
          payload = payload.set('departureDate', departureValidForDefault);
        } else {
          const newDepartureDate = moment(arrivalDate);
          newDepartureDate.add(2, 'days');
          payload = payload.set('departureDate', newDepartureDate);
        }
      } else {
        let newDepartureDate = moment(userSelectedDepartureDate);
        if (rules.departure_date !== null) {
          if (minMaxValueTicketType) {
            const valid_for_max = minMaxValueTicketType.get('valid_for_max');
            const departureValidForMax = getTransformedDate(valid_for_max, moment(arrivalDate));
            if (userSelectedDepartureDate.diff(departureValidForMax, 'days') > 0) {
              newDepartureDate = moment(departureValidForMax);
            }
          }
        } else {
          // days in the park flow
          const daysInPark = parseInt(payload.get('daysInPark'), 10);
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(daysInPark)) {
            newDepartureDate = payload.get('arrivalDate').clone().add(
              daysInPark, 'd',
            );
          } else {
            newDepartureDate = null;
          }
        }
        payload = payload.set('departureDate', newDepartureDate);
      }

      payload = payload.set('fullDiscountInEffect', getFullDiscountInEffect(arrivalDate));

      const finalState = payload.set(
        'errors',
        validateArrivalContact(
          payload,
        ),
      );
      return finalState;
    case 'SET_PARK':
      return defaultState;
    default:
      return state;
  }
};

export default arrivalInformation;
