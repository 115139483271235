import { combineReducers } from 'redux';

import arrivalInformationReducer from './arrivalInformation';
import billingContactReducer from './billingContact';
import categoriesReducer from './categories';
import countriesReducer from './countries';
import partyReducer from './party';
import canCreatePartyReducer from './canCreateParty';
import merchReducer from './merch';
import parksReducer from './parks';
import selectedParkReducer from './selectedPark';
import seasonsReducer from './seasons';
import ticketTypesReducer from './ticketTypes';
import errorsReducer from './errors';
import requestErrorReducer from './requestError';

export default combineReducers({
  party: partyReducer,
  canCreateParty: canCreatePartyReducer,
  errors: errorsReducer,
  requestError: requestErrorReducer,
  arrivalInformation: arrivalInformationReducer,
  billingContact: billingContactReducer,
  categories: categoriesReducer,
  countries: countriesReducer,
  merch: merchReducer,
  parks: parksReducer,
  selectedPark: selectedParkReducer,
  seasons: seasonsReducer,
  ticketTypes: ticketTypesReducer,
});
