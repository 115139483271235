import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'react-loaders';
import Modal from 'react-bootstrap-modal';
import 'loaders.css/loaders.css';

import './main-container.css';
// import Modal from '../modal/modal';


export default class MainContainer extends React.Component {
  static propTypes = {
    waitForLoad: PropTypes.array,
    requestError: PropTypes.object,
    resetRequestError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    waitForLoad: [],
    requestError: null,
  };

  getErrorMessage() {
    const { requestError } = this.props;

    switch (requestError.errorType) {
      case 'PARK_NOT_FOUND':
        return 'This page is not found, please click ok to go back.';
      case 'SERVER_ERROR':
      case 'NO_CONNECTION':
        return 'Your connection has been lost. ' +
            'Please check your Internet connection or try again in a few minutes. ' +
            'If problems persist, park passes can be purchased at the park ' +
            '(visit parksaustralia.gov.au for further information).';
      case 'INVALID_START_DATE':
        return 'Invalid Start Date';
      case 'VALIDATION_ERROR':
        return requestError.error;
      default:
        return 'Couldn\'t connect to the server, please try again later';
    }
  }

  handleClickOK = () => {
    const { requestError, history } = this.props;
    if (requestError && history) {
      let location = '/';
      switch (requestError.errorType) {
        case 'PARK_NOT_FOUND':
          location = '/passes';
          break;
        case 'INVALID_START_DATE':
          location = history.location.pathname;
          break;
        default:
          break;
      }

      history.push(location);
    }

    this.props.resetRequestError();
  }

  render() {
    const {
      requestError, className, waitForLoad, children,
    } = this.props;
    const isLoaded = waitForLoad.every(obj => obj != null);
    const showError = requestError && requestError.errorType !== 'RECAPTCHA_ERROR';
    return (
      <React.Fragment>
        <div className={className}>
          {isLoaded ?
            children :
            !showError && <Loader
              styles={{ margin: '20px auto' }} className='text-center'
              type='ball-pulse-sync' color='#00c1a9'
            />}
        </div>

        {showError && (
          <Modal show onHide={this.props.resetRequestError}>
            <Modal.Body className='display-linebreak'>
              {this.getErrorMessage()}
            </Modal.Body>
            <Modal.Footer>
              <button
                className='btn btn-primary'
                type='button'
                onClick={this.handleClickOK}
              >
                Ok
              </button>
            </Modal.Footer>
          </Modal>)
        }
      </React.Fragment>
    );
  }
}
