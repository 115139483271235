import { createThunkAction, createSimpleAction } from '../helpers';
import request from '../request';
import widgetConfig from '../widgetConfig';

const fetchMerch = createThunkAction('FETCH_MERCH', () => request(
  `/api/frapi/v0/merch/?${widgetConfig.getAuthQuerystring()}`,
));

const updateMerchItem = createSimpleAction(payload => ({
  type: 'UPDATE_MERCH_ITEM',
  payload,
}));

export default {
  fetchMerch,
  updateMerchItem,
};
