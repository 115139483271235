import { fromJS, List } from 'immutable';

const ticketTypes = (state = new List(), action) => {
  switch (action.type) {
    case 'SET_PARK':
      return new List();
    case 'FETCH_TICKET_TYPES_COMPLETED':
      return state.merge(fromJS(action.payload));
    case 'FETCH_SEASONS_COMPLETED':
      if (action.payload.results.length > 0) {
        return fromJS(action.payload.results[0].pass_types).map((newTicketType) => {
          const oldTicketType = state.find(ticket => ticket.get('id') === newTicketType.get('id'));
          return oldTicketType ? oldTicketType.merge(newTicketType) : newTicketType;
        });
      }
      return new List();
    case 'UPDATE_TICKET_TYPE':
      return state.update(
        state.findIndex(item => (item.get('id') === action.payload.get('id'))),
        item => item.merge(action.payload),
      );
    default:
      return state;
  }
};

export default ticketTypes;
