import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import widgetConfig from '../../widgetConfig';

import './park-component.css';

const ParkComponent = ({
  park, selectedPark, setPark, history,
}) => {
  let description = park.get('description');
  const showDots = description.length > 200;
  const logo = park.get('logo');
  const parkName = park.get('name');
  const heritage = park.get('heritage');
  description = showDots ? description.substring(0, 200).trim() : description;

  const onParkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedPark == null || park.get('slug') !== selectedPark.get('slug')) {
      setPark(park);
    }
    history.push(`${widgetConfig.url_prefix}/passes/${park.get('slug')}/`);
  };
  return (
    <div className='col-xs-12 col-sm-6 col-lg-4 col-centered'>
      <div className='thumbnail park-card' onClick={onParkClick} role='presentation'>
        <a className='non-decorated-link' href='/'>
          <div className={`park-header park-${park.get('slug')}`}>
            {heritage && <img className='park-heritage' src={heritage} alt='' />}
            {logo ? (
              <img className='park-logo' src={logo} alt={parkName} />
            ) : (
              <h3>{parkName}</h3>
            )}
          </div>
        </a>
        <img className='img-responsive park-image' src={park.get('image')} alt={park.get('image_alt') || parkName} />
        <div className='caption park-description'>
          <p>
            {description}
            {showDots && (<span>...</span>)}
          </p>
        </div>
      </div>
    </div>
  );
};

ParkComponent.propTypes = {
  park: PropTypes.object.isRequired,
  selectedPark: PropTypes.object,
  setPark: PropTypes.func.isRequired,
};


ParkComponent.defaultProps = {
  selectedPark: null,
};


export default withRouter(ParkComponent);
