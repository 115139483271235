import moment from 'moment';
import mtz from 'moment-timezone';

function evenRound(num, decimalPlaces) {
  const d = decimalPlaces || 0;
  const m = 10 ** d;
  const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n);
  const f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  const r = (
    // eslint-disable-next-line no-nested-ternary
    ((f > 0.5 - e) && (f < 0.5 + e)) ?
      ((i % 2 === 0) ? i : i + 1) : Math.round(n)
  );
  return d ? r / m : r;
}

const roundTo = (val, to = 2) => evenRound(val, to).toFixed(2);

const dateFormat = (date, formatter) => moment(date).format(formatter);

const isIntNumber = num => (
  !Number.isNaN(num) && num != null && !!(num.toString().match(/^-{0,1}\d+$/))
);

const today = () => {
  const now = mtz();
  const localOffset = now.utcOffset();
  now.tz('Australia/Sydney'); // your time zone, not necessarily the server's
  const centralOffset = now.utcOffset();
  const diffInMinutes = centralOffset - localOffset;
  return moment().add(diffInMinutes, 'minutes').startOf('day');
};

const chunks = (array, size) => {
  const results = [];
  while (array.length) {
    results.push(array.splice(0, size));
  }
  return results;
};


const checkPopups = () => {
  const blankWindow = window.open(
    'about:blank',
    '',
    [
      'toolbar=no',
      'status=no',
      'menubar=no',
      'scrollbars=no',
      'resizable=no',
      'left=10000',
      'top=10000',
      'width=10',
      'height=10',
      'visible=none',
    ].join(','),
  );
  try {
    blankWindow.close();
  } catch (e) {
    // eslint-disable-next-line no-alert
    window.alert('Pop-up Blocker is enabled! Please add this site to your exception list.');
  }
  window.focus();
};


const printPDF = (urlToPdfFile/* , printType */) => {
  // printType is render-print or pdf-print
  const createPDFIframe = (doc) => {
    let iframe = doc.getElementById('pdfFrame');
    if (!iframe) {
      iframe = doc.createElement('iframe');
      iframe.id = 'pdfFrame';
      iframe.style.visibility = 'hidden';
      iframe.width = 0;
      iframe.height = 0;
    }
    iframe.src = urlToPdfFile;
    doc.body.appendChild(iframe);
    iframe.contentWindow.onload = function() {
      document.getElementById('pdfFrame').contentWindow.print();
    };

    return new Promise((resolve) => {
      // wait till "next" button is clicked
      // assumes that "next" button sets window.mayBeReloadedAfterPrint to true
      function waitTillResolved() {
        if (window.mayBeReloadedAfterPrint) {
          setTimeout(resolve, 5);
        } else {
          setTimeout(waitTillResolved, 300);
        }
      }
      waitTillResolved();
    });
  };

  return createPDFIframe(document);
};

/**
 * @name getTransformedDate
 * @param {String} validRule - Valid rule fetched from frapi, e.g 12m, 3d, ..
 * @param {Moment.Moment} date - Current date or passed date
 * @return {Moment.Moment} - Extended date after applying rule
 */
const getTransformedDate = (validRule, date = moment()) => {
  if (!validRule) return date;
  const [number, letter] = validRule.match(/[a-z]+|[^a-z]+/gi);
  if (number && letter) {
    switch (letter) {
      case 'm':
        return date.add(parseInt(number, 10), 'months');
      case 'd':
        // current date counts as 1 day
        const addedDay = parseInt(number, 10) - 1;
        return date.add(addedDay, 'days');
      default:
        return date;
    }
  } else {
    return date;
  }
};

// const compareNumber = (a, b) => {
//   if (a > b) return 1;
//   if (a < b) return -1;
//   return 0;
// };

const countTruthyKey = object => Object
  .keys(object)
  .reduce((acc, key) => (object[key] === true ? acc + 1 : acc), 0);

const pick = (...keys) => obj => keys.reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});

const noop = () => false;

const parseDisabledDates = (value) => {
  if (!value) {
    return noop;
  }

  const rules = value.split(/\s*,\s*/).reduce((memo, s) => {
    const match = s.match(/^(\d{4}-\d{2}-\d{2})(\s*-\s*(\d{4}-\d{2}-\d{2}))?$/);

    if (match) {
      const date = moment(match[1]);

      if (match[3]) {
        const dateTo = moment(match[3]).add(1, 'day');

        memo.push(v => date.isSameOrBefore(v) && dateTo.isAfter(v));
      } else {
        memo.push(v => date.isSame(v, 'day'));
      }
    }

    return memo;
  }, []);

  return date => rules.some(rule => rule(date));
};

export {
  parseDisabledDates,
  checkPopups,
  printPDF,
  roundTo,
  today,
  chunks,
  dateFormat,
  isIntNumber,
  getTransformedDate,
  countTruthyKey,
  pick,
  // compareNumber,
};
