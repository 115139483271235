import Cookies from '../helpers/cookies';
import widgetConfig from '../widgetConfig';

// eslint-disable-next-line no-nested-ternary
const prefix = process.env.NODE_ENV !== 'production' ? (process.env.REACT_APP_PARKS_API_HOST ? process.env.REACT_APP_PARKS_API_HOST : 'http://localhost:8000') : '';
const makeUrl = url => `${prefix}${url}`;


const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 400) {
    return response;
  }

  if (response.status >= 400 && response.status < 500) {
    return response.json()
      .then((data) => {
        const error = new Error(response.statusText);
        error.response = response;
        error.jsonData = data;
        throw error;
      });
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};


const csrfSafeMethod = method => /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);


const getResponseBody = (response) => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json();
  }
  return response.text();
};


const request = (url, params = {}) => {
  const { formData, ...restParams } = params;
  const requestParams = Object.assign({}, restParams, {
    method: restParams.method || 'GET',
    credentials: 'same-origin',
    // mode: 'no-cors',
    headers: restParams.headers ? new Headers(restParams.headers) : new Headers(),
  });

  // if (process.env.NODE_ENV !== 'production') {
  //   requestParams.mode = 'cors';
  //   requestParams.credentials = 'include';
  // }

  const { headers } = requestParams;

  if (process.env.NODE_ENV === 'production' &&
      !(csrfSafeMethod(params.method) ||
      headers.has('X-CSRFToken'))) {
    const csrftokenInputs = document.getElementsByName('csrfmiddlewaretoken');
    let csrftoken;
    if (csrftokenInputs.length > 0) {
      csrftoken = csrftokenInputs[0].value;
    } else {
      csrftoken = Cookies.get('csrftoken');
    }
    headers.append('X-CSRFToken', csrftoken);
  }

  if (!headers.has('Content-type') && !formData) {
    headers.append('Content-Type', 'application/json');
  }
  if (widgetConfig.bearerToken) {
    headers.append('Authorization', `Bearer ${widgetConfig.bearerToken}`);
  }

  const retryDelay = 1000;
  const retries = 5;

  return new Promise((resolve, reject) => {
    const wrappedFetch = (numOfRet) => {
      fetch(makeUrl(url), requestParams)
        .then(checkStatus)
        .then(getResponseBody)
        .then(resolve)
        .catch((error) => {
          if (error instanceof TypeError && numOfRet > 0) {
            if (!url.startsWith('/api/frapi/v0/party/')) {
              // we don't schedule retries for the POST party create request
              window.setTimeout(() => {
                wrappedFetch(numOfRet - 1);
              }, retryDelay);
            }
          }
          reject(error);
        });
    };
    wrappedFetch(retries);
  });
};

export default request;
