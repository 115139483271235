import { fromJS, List, Map } from 'immutable';
import { isValidNumber } from 'libphonenumber-js';
import Cookies from '../helpers/cookies';
import { printPDF } from '../helpers/utils';
import widgetConfig from '../widgetConfig';


function getRelativeUrl(fullUrl) {
  return `/${fullUrl.replace(/^(?:\/\/|[^/]+)*\//, '')}`;
}

const groupTicketsById = tickets => (
  tickets.groupBy(t => t.get('id')).map((groupedTickets, id) => new Map({
    id,
    count: groupedTickets.size,
    tickets: groupedTickets,
  })).toList()
);


const tryToMakeRedirect = (link, timeout = 100) => {
  if (window.location !== link) {
    window.location = link;
    // increase timeout to reduce situation when
    // browsers wait too long for response from the server
    setTimeout(
      tryToMakeRedirect,
      timeout,
      link,
      timeout + 1000,
    );
  }
};


const party = (state = null, action) => {
  switch (action.type) {
    case 'SET_PARK':
      return null;
    case 'PREPARE_PARTY':
      const newParty = fromJS(action.payload);
      if (state) {
        // group tickets by id
        const groupedTickets = groupTicketsById(newParty.get('tickets'));
        const oldGroupedTickets = groupTicketsById(state.get('tickets'));
        const mergedTickets = groupedTickets.map((group) => {
          const foundGroup = oldGroupedTickets.find(g => g.get('id') === group.get('id'));
          if (foundGroup) {
            if (group.get('count') !== foundGroup.get('count')) {
              // update certain tickets if they were filled earlier
              const oldTickets = foundGroup.get('tickets');
              const tickets = group.get('tickets').map((ticket, idx) => {
                if (oldTickets.size > idx) {
                  return oldTickets.get(idx);
                }
                return ticket;
              }).toList();
              return group.set('tickets', tickets);
            }
            return foundGroup;
          }
          return group;
        }).reduce(
          (lst, group) => lst.concat(group.get('tickets')),
          new List(),
        ); // transform back to tickets
        return newParty.set('tickets', mergedTickets);
      }
      return newParty;
    case 'UPDATE_TICKET':
      if (state) {
        const tickets = state.get('tickets');
        if (tickets) {
          // eslint-disable-next-line prefer-const
          let { id, ticket } = action.payload;
          // handle phone validation
          const phone = ticket.get('phone');
          if (phone && phone > 0) {
            let ticketValidationRules = ticket.get('ticketValidationRules');
            ticketValidationRules = ticketValidationRules.set('phoneValid', isValidNumber(phone));
            ticket = ticket.set('ticketValidationRules', ticketValidationRules);
          }
          return state.set('tickets', tickets.set(id, ticket));
        }
      }
      return state;
    case 'POST_PARTY_COMPLETED':
      const { payload } = action;
      let isPrintPartyManifest = false;
      let newState = state;
      if (action.restData.length) {
        isPrintPartyManifest = action.restData[0].get('isPrintPartyManifest');
      }
      if (payload.redirect) {
        if (widgetConfig.retailerType === 'park' && isPrintPartyManifest && payload.redirect.print_url) {
          // print pdf file
          let pdf_url = payload.redirect.print_url;
          if (isPrintPartyManifest === 'pdf-print-manifest') {
            pdf_url = payload.redirect.manifest_url;
          }
          printPDF(pdf_url, isPrintPartyManifest).then(() => {
            // start a new party creation
            let createNewPartyLink = `${window.location.origin}${widgetConfig.url_prefix}/passes/${state.get('parkSlug')}/`;
            if (widgetConfig.mobileView) {
              createNewPartyLink = `${createNewPartyLink}?mobileView=${widgetConfig.mobileView}`;
            }
            tryToMakeRedirect(createNewPartyLink);
          });
        } else if (window.history) {
          window.history.pushState(
            null,
            'Pass Manage Page',
            getRelativeUrl(action.payload.redirect.back_url),
          );
          window.history.pushState(
            null,
            'Make a payment',
            getRelativeUrl(action.payload.redirect.to),
          );
          window.location.reload();
        } else {
          Cookies.set('backUrl', action.payload.redirect.back_url, 36e5); // expires in 1h
          window.location.href = action.payload.redirect.to;
        }

        if (payload.redirect.void_sale_url) {
          newState = state.set('void_sale_url', payload.redirect.void_sale_url);
        }
      }
      return newState;
    default:
      return state;
  }
};

export default party;
