import { List } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import { getTransformedDate } from '../../helpers/utils';
import widgetConfig from '../../widgetConfig';

import TicketType from '../ticket-type/ticket-type';
import FormattedPrice from '../formatted-price/formatted-price';

import './ticket-choose.css';

function isFamilyTicketApplicable(ticketTypesByCategory) {
  let showFamilyTicketHint = false;
  const childTicket = ticketTypesByCategory.find((ticketType) => {
    const isChildTicket = ticketType.get('name').toLowerCase().indexOf('child') !== -1;
    const isPayTicket = ticketType.get('price_retail') !== 0;
    return isChildTicket && isPayTicket;
  });
  const adultTicket = ticketTypesByCategory.find((ticketType) => {
    const isAdultTicket = ticketType.get('name').toLowerCase().indexOf('adult') !== -1;
    const isPayTicket = ticketType.get('price_retail') !== 0;
    return isAdultTicket && isPayTicket;
  });
  if (childTicket && adultTicket) {
    showFamilyTicketHint = window.parseInt(childTicket.get('count')) >= 2 &&
      window.parseInt(adultTicket.get('count')) >= 2;
  }
  return showFamilyTicketHint;
}
export default class TicketChoose extends React.Component {
  /*
  The list of ticket types with qty, price and details.
  This component draws the whole table with headers and footers, but outsource
  the specific ticket type rows to the TicketType components.
  */
  static propTypes = {
    season: PropTypes.object.isRequired,
    categoryId: PropTypes.string,
    widgetType: PropTypes.string,
    maxTicketCount: PropTypes.number,
    billingInformation: PropTypes.object.isRequired,
    parkSlug: PropTypes.string.isRequired,
    categories: PropTypes.object.isRequired,
    ticketTypes: PropTypes.object.isRequired,
    updateTicketType: PropTypes.func.isRequired,
    arrivalInformation: PropTypes.object.isRequired,
    billingValidationRules: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    mobileView: PropTypes.bool,
    defaultDaysInPark: PropTypes.number,
  };

  static defaultProps = {
    categoryId: null,
    widgetType: null,
    maxTicketCount: 100,
    mobileView: false,
    defaultDaysInPark: 1,
  };

  componentDidUpdate(prevProps) {
    if (this.props.arrivalInformation.get('daysInPark') == null && this.props.billingValidationRules.days_in_the_park) {
      this.onChange(this.props.defaultDaysInPark, 'daysInPark');
    }
  }

  onChange(value, property) {
    let data = this.props.arrivalInformation;
    if (property == 'daysInPark' && Number.isNaN(parseInt(value, 10))) {
      data = data.set(property, null);
    } else {
      data = data.set(property, value);
    }
    data = data.set(
      'billingValidationRules', this.props.billingValidationRules,
    );
    this.props.onChange(data);
  }

  getVisibleCategory = () => {
    const { categoryId, categories } = this.props;
    return categoryId ?
      categories.find(category => category.get('id') == categoryId) :
      categories.get(0);
  };

  updateDepartureDate() {
    const { ticketTypes, arrivalInformation, billingValidationRules } = this.props;
    const visibleCategory = this.getVisibleCategory();
    const ticketTypesByCategory = visibleCategory ? (
      ticketTypes.filter(
        ticketType => ticketType.get('category') === visibleCategory.get('id'),
      )
    ) : (
      new List()
    );
    const departureDate = arrivalInformation.get('departureDate');
    const arrivalDate = arrivalInformation.get('arrivalDate');
    const userSelectedDepartureDate = arrivalInformation.get('userSelectedDepartureDate');

    // Use for updating the departure date when ticket type changed
    const selectedTickets = ticketTypesByCategory.filter(ticketType => ticketType.get('count') > 0);
    // get min value of valid_for_default from the list of selected tickets
    const minValueTicketType = selectedTickets.minBy((ticketType) => {
      const valid_for_default = ticketType.get('valid_for_default');
      if (valid_for_default) {
        const [number, letter] = valid_for_default.match(/[a-z]+|[^a-z]+/gi);
        let totalDays = parseInt(number, 10);
        if (letter === 'm') {
          totalDays = totalDays * 30; // 30 days per month
        }
        return totalDays;
      }
      return 9999;
    });

    // If user already selected departure date, no need to renew the date
    // [Update] If default date is before user selected departure date, it overrides
    if (minValueTicketType) {
      const valid_for_default = minValueTicketType.get('valid_for_default');
      const departureValidForDefault = getTransformedDate(valid_for_default, moment(arrivalDate));
      if (
        (!userSelectedDepartureDate) ||
        (moment(departureValidForDefault).isBefore(userSelectedDepartureDate))
      ) {
        if ((departureValidForDefault.diff(departureDate, 'days') !== 0 || departureDate === null) && billingValidationRules.departure_date !== null) {
          this.onChange(departureValidForDefault, 'departureDate');
        }
      }
    }
  }

  // updateDepartureDate relies on UPDATE_TICKET_TYPE state merge
  updateDepartureDateDelayed() {
    setTimeout(() => {
      this.updateDepartureDate();
    }, 400);
  }

  render() {
    const {
      season,
      arrivalInformation,
      billingInformation, widgetType, parkSlug,
      categories, ticketTypes,
      maxTicketCount, // party-wide ticket limit, for example 100 for FIT purchases,
      mobileView,
    } = this.props;
    const visibleCategory = this.getVisibleCategory();

    let identityProofHintCategory = null; // null or category name where such pass is selected
    let selectedCategory = null; // the category where the passes are selected
    ticketTypes.forEach((tt) => {
      if (tt.get('count')) {
        selectedCategory = tt.get('category');
        const consession = (
          tt.get('name').toLowerCase().indexOf('concession') !== -1
        ) || (identityProofHintCategory === 'concession');
        const secondvehicle = (
          tt.get('name').toLowerCase().indexOf('second vehicle') !== -1
        ) || (identityProofHintCategory === '2ndvehicle');

        if (tt.get('document_upload_required')) {
          if (consession && secondvehicle) {
            // always wins
            identityProofHintCategory = 'concession_2ndvehicle';
          } else if (!identityProofHintCategory) {
            if (consession) {
              identityProofHintCategory = 'concession';
            } else if (secondvehicle) {
              identityProofHintCategory = '2ndvehicle';
            } else {
              identityProofHintCategory = tt.get('category');
            }
          }
        }
      }
    });

    const showCategoryNameToIdentityHint = (
      // do it only if we don't have any specific custom text
      identityProofHintCategory &&
      identityProofHintCategory !== 'concession' &&
      identityProofHintCategory !== '2ndvehicle' &&
      identityProofHintCategory !== 'concession_2ndvehicle'
    );
    if (showCategoryNameToIdentityHint) {
      identityProofHintCategory = categories.find(
        category => category.get('id') == identityProofHintCategory,
      ).get('name');
    }

    const totalTicketCount = ticketTypes.reduce((total, ticketType) => (
      total + (ticketType.get('count') || 0)
    ), 0);

    const ticketTypesByCategory = visibleCategory ? (
      ticketTypes.filter(
        ticketType => ticketType.get('category') === visibleCategory.get('id'),
      )
    ) : (
      new List()
    );

    const totalAmount = billingInformation.get('totalTicketsAmount');
    const totalDiscount = billingInformation.get('totalDiscount');
    const showFamilyTicketHint = isFamilyTicketApplicable(ticketTypesByCategory);

    // concession/visitor/annual pass document upload message
    let identityMessage = (
      `Note: ${identityProofHintCategory} passes require photographic ID to be ` +
      `uploaded on your pass management page after ordering.`
    );
    if (identityProofHintCategory === 'concession_2ndvehicle') {
      identityMessage = (
        <div>
          <p>
            Note: Concession passes require proof of concession to be uploaded on
            your pass management page after ordering.
          </p>
          <p>
            Second vehicle must be registered to the same address. Pass may not be
            valid if proof can not be produced when asked.
          </p>
        </div>
      );
    } else if (identityProofHintCategory === 'concession') {
      identityMessage = (
        'Note: Concession passes require proof of concession to be uploaded ' +
        'on your pass management page after ordering.'
      );
    } else if (identityProofHintCategory === '2ndvehicle') {
      identityMessage = (
        'Second vehicle must be registered to the same address. Pass may not be' +
        ' valid if proof can not be produced when asked.'
      );
    }

    return (
      <div className='ticket-choose'>
        <ul className='nav nav-tabs nav-justified'>
          {categories.map(category => (
            <li
              key={category.get('id')}
              aria-label={`${category.get('name')} passes for ${season.get('name')}`}
              aria-current={visibleCategory.get('id') === category.get('id') ? 'page' : undefined}
              className={visibleCategory.get('id') === category.get('id') ? 'active' : ''}
            >
              <Link
                to={`${widgetConfig.url_prefix}/passes/${parkSlug}/category/${category.get('id')}/`}
              >
                <strong>{category.get('name')}</strong>
              </Link>
            </li>
          ))}
        </ul>

        <div className='row flex-box row-table row-table-bordered row-table-head ticket-type-table-head'>
          <div className='pass-type-head'>
            <strong>Pass type</strong>
          </div>

          <div className='flex-box price-qty-head big-device'>
            <div className='cell-center text-right price-head'>
              <strong>Price</strong>
            </div>
            <div className={`cell-center qty-head ${mobileView && 'touch-screen-mode'}`}>
              <strong>Quantity</strong>
            </div>
          </div>
        </div>
        <div className="ticket-choose-items">
          {ticketTypesByCategory.map(ticketType => (
            <TicketType
              key={ticketType.get('id')}
              className='row row-table'
              partyWideTicketLimitRemains={
                // set the limit to 0 if multi-category sales disabled for this user
                // and there are another passes selected from another category
                (
                  widgetConfig.isMultiCategorySaleAllowed
                ) || (
                  !selectedCategory || selectedCategory === ticketType.get('category')
                )
                  ?
                  maxTicketCount - totalTicketCount
                  :
                  0
              }
              isEnabled={
                (
                  widgetConfig.isMultiCategorySaleAllowed
                ) || (
                  !selectedCategory || selectedCategory === ticketType.get('category')
                )
              }
              ticketType={ticketType}
              mobileView={this.props.mobileView}
              onChange={(tt) => {
                this.props.updateTicketType(tt);
                this.updateDepartureDateDelayed();
              }}
            />
          ))}
        </div>
        <div className='row row-table row-table-bordered'>
          <div className='result-table'>
            {widgetType !== 'retail' ? (
              <>
                <span className='col-no-padding'>
                  <h3>Total Price:</h3>
                </span>
                <span className='col-no-padding total-price-number'>
                  <h3><FormattedPrice value={totalAmount} /></h3>
                  <div className='hidden-col-price' />
                </span>
              </>
            ) : (
              <>
                <span className='col-no-padding'>
                  {!arrivalInformation.get('fullDiscountInEffect') && (
                    <small>Sales Commission:</small>
                  )}
                  <h4>Total Price:</h4>
                </span>
                <span className='col-no-padding total-price-number'>
                  {!arrivalInformation.get('fullDiscountInEffect') && (
                    <small>
                      <FormattedPrice value={totalAmount - totalDiscount} />
                    </small>
                  )}
                  <h4><FormattedPrice value={totalAmount} /></h4>
                  <div className='hidden-col-price' />
                </span>
              </>
            )}
          </div>
        </div>
        {identityProofHintCategory && (
          <div className='alert alert-info col-xs-12 col-sm-12 col-md-12 col-lg-12'>
            {identityMessage}
          </div>
        )}
        {showFamilyTicketHint && (
          <div className='alert alert-info col-xs-12 col-sm-12 col-md-12 col-lg-12'>
            You may be able to save on this order by purchasing a Family Pass if you are
            a family of two adults and two or more children.
          </div>
        )}
      </div>
    );
  }
}
