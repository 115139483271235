const { isArray } = Array;

const getTypes = (type) => {
  if (isArray(type)) {
    const [START, COMPLETED, FAIL] = type;
    return [START, COMPLETED, FAIL];
  }
  return ['START', 'COMPLETED', 'FAIL'].map(postfix => `${type}_${postfix}`);
};

const prepareAction = (type, payload, ...restData) => ({
  type,
  payload,
  restData,
});

const isFunction = target => typeof target === 'function';


const createThunkAction = (type, makeRequest, success, fail) => {
  const [START, COMPLETED, FAIL] = getTypes(type);
  return (dispatch, getState) => (data, ...args) => {
    dispatch(prepareAction(START, data, ...args));
    return makeRequest(data, ...args, getState).then((responseData) => {
      dispatch(prepareAction(COMPLETED, responseData, data));
      if (success && isFunction(success)) {
        success(dispatch, responseData, data, ...args, getState);
      }
    }).catch((error) => {
      dispatch(prepareAction(FAIL, error));
      if (fail && isFunction(fail)) {
        fail(dispatch, error);
      }
    });
  };
};

export const createSimpleAction = (actionHandler, afterDispatch) => (
  (dispatch, getState) => (
    payload => (
      new Promise((res) => {
        dispatch(actionHandler(payload, dispatch, getState));
        res(payload);
      }).then((resPayload) => {
        if (afterDispatch && isFunction(afterDispatch)) {
          afterDispatch(resPayload, dispatch, getState);
        }
      })
    )
  )
);

export default createThunkAction;
