import PropTypes from 'prop-types';
import React from 'react';
import widgetConfig from '../../widgetConfig';
import FormField from '../form-field/form-field';
import TypeaheadSelector from '../typeahead-selector/typeahead-selector';
import {
  EmailValidator, MaxLengthValueValidator, MinLengthValueValidator, NTPostcodeValidator,
  NumberValidator, validateRequiredBillingContact,
} from '../validator/validator';


export default class BillingContact extends React.Component {
  static propTypes = {
    billingContact: PropTypes.object.isRequired,
    countries: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    termsLink: PropTypes.string.isRequired,
    mobileView: PropTypes.bool.isRequired,
  };

  static defaultProps = {};
  onVisitorChange = (value, property) => {
    const visitors = parseInt(value, 10);
    if (!value) {
      this.onChange(null, property);
    }
    if (Number.isInteger(visitors) && Math.sign(visitors) === 1 && visitors <= 25) {
      this.onChange(visitors, property);
    }
  };

  componentDidMount() {
    this.onChange();
  }

  onChange(value, property) {
    // we attach billing validation rules (which we have) so further validations
    // may respect it without need to re-validate it
    let dataToValidate = this.props.billingContact.set(
      'billingValidationRules', this.billingValidationRules,
    );
    if (property) {
      dataToValidate = dataToValidate.set(
        property, value,
      );
    }
    if (typeof this.billingValidationRules.postcode === 'string' && this.billingValidationRules.postcode !== 'nt') {
      dataToValidate = dataToValidate.set('postcode', this.billingValidationRules.postcode);
    }
    if (typeof this.billingValidationRules.country === 'string') {
      dataToValidate = dataToValidate.set('country', this.billingValidationRules.country);
    }

    this.props.onChange(dataToValidate);
  }

  checkFocus() {
    const { billingValidationRules, billingContact, errors } = this.props;
    const mapFields = [
      ['email', 'email'],
      ['firstName', 'first_last_name'],
      ['lastName', 'first_last_name'],
      ['country', 'country'],
      ['phone', 'phone'],
      ['vehicle', 'rego'],
      ['company', 'company'],
      ['purpose', 'purpose'],
      ['agreement', 'agreement'],
      ['visitors', 'visitors'],
    ];
    const fieldError = validateRequiredBillingContact(
      mapFields, billingContact.toJS(), billingValidationRules, errors);
    if (fieldError && this[fieldError] && this[fieldError].focus) {
      if (fieldError === 'phone') {
        this.phoneWrapper.scrollIntoView({ behavior: 'smooth' });
      } else {
        this[fieldError].scrollIntoView({ behavior: 'smooth' });
      }
      setTimeout(() => {
        this[fieldError].focus();
      }, 500);
      return true;
    }
    return false;
  }

  render() {
    const {
      countries,
      termsLink,
      billingValidationRules,
      mobileView,
      billingContact,
    } = this.props;

    this.billingValidationRules = billingValidationRules;

    const options = countries.toJS().map(({ code, name }) => ({ id: code, label: name }));
    const country = billingContact.get('country');
    const isPostcodeRequired = (country === 'AU') && !!billingValidationRules.postcode;
    const showIdentificationCheckbox = (
      widgetConfig.widgetType === 'retail' && billingValidationRules.document_upload_required
    );

    let postCodeValidators = [];
    if (isPostcodeRequired) {
      postCodeValidators = [
        new MinLengthValueValidator(4),
        new MaxLengthValueValidator(4),
        new NumberValidator(),
      ];
      if ((typeof billingValidationRules.postcode === 'string' && /nt/i.test(billingValidationRules.postcode))) {
        postCodeValidators.push(new NTPostcodeValidator());
      }
    }


    return (
      <div className='billing-contact'>
        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
            <h3>Billing Contact</h3>
          </div>
        </div>
        <form>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 form-field-wrapper'>
              <FormField
                refInput={(inputComponent) => { this.email = inputComponent; }}
                required={billingValidationRules.email}
                type='email'
                name='email'
                label={`Email${billingValidationRules.email ? '*' : ''}`}
                tooltip='We will email your order confirmation and important information to you'
                value={billingContact.get('email')}
                validators={[new EmailValidator()]}
                onChange={(...props) => this.onChange(...props)}
              />
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 form-field-wrapper'>
              <FormField
                refInput={(inputComponent) => { this.firstName = inputComponent; }}
                required={billingValidationRules.first_last_name}
                name='firstName'
                label={`First name${billingValidationRules.first_last_name ? '*' : ''}`}
                tooltip='Initial or full first name'
                value={billingContact.get('firstName')}
                validators={[new MinLengthValueValidator(1), new MaxLengthValueValidator(50)]}
                onChange={(...props) => this.onChange(...props)}
              />
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 form-field-wrapper'>
              <FormField
                refInput={(inputComponent) => { this.lastName = inputComponent; }}
                required={billingValidationRules.first_last_name}
                name='lastName'
                label={`Last name${billingValidationRules.first_last_name ? '*' : ''}`}
                tooltip='Full last name'
                value={billingContact.get('lastName')}
                validators={[new MinLengthValueValidator(2), new MaxLengthValueValidator(50)]}
                onChange={(...props) => this.onChange(...props)}
              />
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 form-field-wrapper'>
              <TypeaheadSelector
                required={!!billingValidationRules.country}
                name='country'
                label={`Country${billingValidationRules.country ? '*' : ''}`}
                tooltip='Country of residence'
                options={options}
                selectedValue={country}
                onChange={(value, name) => this.onChange(value, name)}
                inputProps={{
                  'aria-label': 'country of residence',
                }}
              />
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 form-field-wrapper'>
              <FormField
                refInput={(inputComponent) => { this.postcode = inputComponent; }}
                required={isPostcodeRequired}
                disabled={country !== 'AU'}
                name='postcode'
                label={`Postcode${isPostcodeRequired ? '*' : ''}`}
                title={
                  typeof billingValidationRules.postcode === 'string'
                    ?
                    `Must be ${billingValidationRules.postcode}`
                    :
                    '4 digits for Australia'
                }
                value={billingContact.get('postcode')}
                validators={
                  postCodeValidators
                }
                onChange={(...props) => this.onChange(...props)}
              />
            </div>
            <div
              ref={(ref) => { this.phoneWrapper = ref; }}
              className='col-xs-12 col-sm-12 col-md-6 col-lg-6 form-field-wrapper'
            >
              <FormField
                refInput={(inputComponent) => { this.phone = inputComponent; }}
                required={billingValidationRules.phone}
                name='phone'
                componentClass='phone'
                label={`Mobile${billingValidationRules.phone ? '*' : ''}`}
                tooltip='We will SMS a mobile version of your pass to you'
                secondTooltip={!billingContact.get('phoneValid')
                  ? 'The mobile number you have entered may be invalid. You may still proceed if it looks correct'
                  : null}
                value={billingContact.get('phone')}
                indicateInvalid
                flagsPath='/static/flag-icon-css/flags/4x3/'
                onChange={(...props) => this.onChange(...props)}
                country={billingContact.get('phoneCountry')}
                onCountryChange={(newCountry) => { this.onChange(newCountry, 'phoneCountry'); }}
              />
            </div>
            {billingValidationRules.rego != null && (
              <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 form-field-wrapper'>
                <FormField
                  refInput={(inputComponent) => { this.vehicle = inputComponent; }}
                  required={billingValidationRules.rego}
                  name='vehicle'
                  label={`Vehicle Registration${billingValidationRules.rego ? '*' : ''}`}
                  tooltip='Enter "HIRE CAR" if you are hiring a vehicle and do not know the registration.'
                  value={billingContact.get('vehicle')}
                  onChange={(...props) => this.onChange(...props)}
                />
              </div>
            )}
            {billingValidationRules.visitors !== null && (
              <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 form-field-wrapper'>
                <FormField
                  refInput={(inputComponent) => { this.visitors = inputComponent; }}
                  required={billingValidationRules.visitors}
                  name='visitors'
                  label={`Visitors${billingValidationRules.visitors ? '*' : ''}`}
                  tooltip='Please enter the estimated number of persons travelling with you'
                  value={billingContact.get('visitors')}
                  type='number'
                  min='0'
                  max='25'
                  onChange={(...props) => this.onVisitorChange(...props)}
                />
              </div>
            )}

            { billingValidationRules.company !== null && (
              <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 form-field-wrapper'>
                <FormField
                  refInput={(inputComponent) => { this.company = inputComponent; }}
                  required={billingValidationRules.company}
                  maxLength='128'
                  name='company'
                  label={`Company${billingValidationRules.company ? '*' : ''}`}
                  value={billingContact.get('company')}
                  onChange={(...props) => this.onChange(...props)}
                />
              </div>
            ) }
            { billingValidationRules.purpose !== null && (
              <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 form-field-wrapper'>
                <FormField
                  refInput={(inputComponent) => { this.purpose = inputComponent; }}
                  required={billingValidationRules.purpose}
                  name='purpose'
                  maxLength='30'
                  label={`Purpose${billingValidationRules.purpose ? '*' : ''}`}
                  value={billingContact.get('purpose')}
                  helpText='e.g. permit number, project name, reason for entry, etc.'
                  onChange={(...props) => this.onChange(...props)}
                />
              </div>
            ) }
            <div className='col-xs-12'>
              <label htmlFor='id_billing_subscribe' className="thin">
                <span>
                  <input
                    ref={(inputComponent) => { this.subscribe = inputComponent; }}
                    name='subscribe'
                    type='checkbox'
                    id='id_billing_subscribe'
                    checked={billingContact.get('subscribe')}
                    onChange={evt => this.onChange(evt.target.checked, 'subscribe')}
                  />&nbsp;
                  {
                    mobileView ? (
                      <React.Fragment>
                        Subscribe to Parks Australia.
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        I would like to receive more information about
                        Australia’s national parks from Parks Australia.
                      </React.Fragment>
                    )
                  }
                </span>
              </label>
            </div>
            <div className='col-xs-12'>
              <label htmlFor='id_receive_sms' className="thin">
                <span>
                  <input
                    ref={(inputComponent) => { this.receive_sms = inputComponent; }}
                    name='receive_sms'
                    type='checkbox'
                    id='id_receive_sms'
                    checked={billingContact.get('receive_sms')}
                    onChange={evt => this.onChange(evt.target.checked, 'receive_sms')}
                  />&nbsp;
                  I would like to receive a mobile version of my pass.
                </span>
              </label>
            </div>
            { showIdentificationCheckbox && (
              <div className='col-xs-12'>
                <label htmlFor='id_identification_sighted' className="thin">
                  <span>
                    <input
                      ref={(inputComponent) => { this.receive_sms = inputComponent; }}
                      name='identification_sighted'
                      type='checkbox'
                      id='id_identification_sighted'
                      checked={billingContact.get('identification_sighted')}
                      onChange={evt => this.onChange(evt.target.checked, 'identification_sighted')}
                    />&nbsp;
                    <strong>*</strong> Identification sighted and valid
                  </span>
                </label>
              </div>
            )}
            <div className='col-xs-12'>
              <label htmlFor='id_billing_agree' className="thin">
                <span>
                  <input
                    ref={(inputComponent) => { this.agreement = inputComponent; }}
                    name='agreement'
                    type='checkbox'
                    id='id_billing_agree'
                    checked={billingContact.get('agreement')}
                    onChange={evt => this.onChange(evt.target.checked, 'agreement')}
                  />
                  &nbsp;
                  {
                    mobileView ? (
                      <React.Fragment>
                        <strong>*</strong>&nbsp;
                        <a href={`/${termsLink}`} target='_blank'>
                          terms and conditions of issue and park entry
                          <span className="sr-only"> (opens in new tab)</span>
                        </a>
                        &nbsp;
                        and&nbsp;
                        <a href='https://parksaustralia.gov.au/privacy/' rel='noopener noreferrer' target='_blank'>
                          privacy notice
                          <span className="sr-only"> (opens in new tab)</span>
                        </a>.
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <strong>*</strong> I agree to the&nbsp;
                        <a href={`/${termsLink}`} target='_blank'>
                          terms and conditions of issue and park entry
                          <span className="sr-only"> (opens in new tab)</span>
                        </a>
                        &nbsp;and agree to the collection of personal
                        information under the terms of the&nbsp;
                        <a href='https://parksaustralia.gov.au/privacy/' rel='noopener noreferrer' target='_blank'>
                          privacy notice
                          <span className="sr-only"> (opens in new tab)</span>
                        </a>.
                      </React.Fragment>
                    )
                  }
                </span>
              </label>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
