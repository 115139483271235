import { createThunkAction } from '../helpers';
import countries from '../helpers/countries.json';


const fetchCountries = createThunkAction('FETCH_COUNTRIES', () => (
  Promise.resolve(countries)
));

export default {
  fetchCountries,
};
