import { List, fromJS } from 'immutable';
// import widgetConfig from '../widgetConfig';

const parks = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_PARKS_COMPLETED':
      return new List(fromJS(action.payload.results));
    default:
      return state;
  }
};

export default parks;
