import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { IMask, IMaskInput } from 'react-imask';
import 'react-bootstrap-modal/lib/css/rbm-patch.css';
import 'react-day-picker/lib/style.css';
import DatePickerSelector from '../datepicker/datepicker';
import FormField from '../form-field/form-field';
import { today } from '../../helpers/utils';

import './arrival-form.css';

const momentFormat = 'DD/MM/YYYY';

const DateMask = {
  mask: Date,
  pattern: momentFormat,
  lazy: false,
  autofix: true,
  overwrite: true,
  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      placeholderChar: 'y',
      from: new Date().getFullYear(),
      to: 9999,
    },
    MM: {
      mask: IMask.MaskedRange,
      placeholderChar: 'm',
      from: 1,
      to: 12,
    },
    DD: {
      mask: IMask.MaskedRange,
      placeholderChar: 'd',
      from: 1,
      to: 31,
    },
  },
  format(date) {
    return moment(date).format(momentFormat);
  },
  parse(str) {
    return moment(str, momentFormat);
  },
};

const DateInput = ({ value, onAccept }) => {
  const [inputValue, setInputValue] = useState();
  const handleAccept = useCallback((v, m) => {
    setInputValue(v);
    onAccept(m.typedValue);
  }, [onAccept]);

  useEffect(() => setInputValue(undefined), [value]);

  return <IMaskInput
    {...DateMask}
    className="form-control"
    value={inputValue ?? value}
    onAccept={handleAccept}
  />;
};

export default class ArrivalForm extends React.Component {
  static propTypes = {
    arrivalInformation: PropTypes.object.isRequired,
    billingValidationRules: PropTypes.object.isRequired,
    defaultDaysInPark: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    parkClosure: PropTypes.shape({
      closureLink: PropTypes.string,
      isDateClosed: PropTypes.func,
    }),
  };

  static defaultProps = {
    parkClosure: null,
    defaultDaysInPark: 1,
  };

  constructor(props) {
    super(props);
    this.state = {
      showDatePicker: false,
      invalidArrivalDate: null,
    };
  }
  onChange(value, property) {
    let data = this.props.arrivalInformation;
    if (property == 'daysInPark' && Number.isNaN(parseInt(value, 10))) {
      data = data.set(property, null);
    } else {
      data = data.set(property, value);
    }
    if (data.get('daysInPark') == null && this.props.billingValidationRules.days_in_the_park) {
      data = data.set('daysInPark', this.props.defaultDaysInPark);
    }
    data = data.set(
      'billingValidationRules', this.props.billingValidationRules,
    );
    this.props.onChange(data);
  }

  isDateDisabled = (date) => {
    if (today().isAfter(date)) return true;
    const { parkClosure } = this.props;
    return parkClosure && !parkClosure.closureLink && parkClosure.isDateClosed(date);
  };

  render() {
    const { invalidArrivalDate } = this.state;
    const {
      arrivalInformation,
      season,
    } = this.props;

    const toggleDatePicker = (showDatePicker) => {
      this.setState({ showDatePicker });
    };

    // hide the datepicker after the date has been chosen
    // do not let the departure date be before arrival
    const onDateChange = (date) => {
      if (date) {
        const { parkClosure } = this.props;
        if (parkClosure && parkClosure.closureLink && parkClosure.isDateClosed(date)) {
          window.location.href = parkClosure.closureLink;
        } else if (this.isDateDisabled(date)) {
          this.setState({ invalidArrivalDate: date });
        } else {
          this.setState({ invalidArrivalDate: null });
          this.onChange(date, 'arrivalDate');
        }
      } else if (date === '') {
        this.setState({ invalidArrivalDate: null });
        this.onChange(null, 'arrivalDate');
      }
      toggleDatePicker(false);
    };

    const arrivalDate = arrivalInformation.get('arrivalDate');
    return (
      <React.Fragment>
        <div className='arrival-field-wrapper flex-1 basic-50'>
          <FormField
            componentClass={DateInput}
            required={!!season}
            validationState={invalidArrivalDate && 'error'}
            name='arrivalDate'
            label='Arrival date*'
            value={(invalidArrivalDate ?? arrivalDate)?.format(momentFormat)}
            onAccept={onDateChange}
            helpText={
              invalidArrivalDate
                ? 'The selected arrival date is not valid'
                : 'You may update your arrival date once prior to arrival within the season'
            }
          >
            <span className="input-group-btn">
              <button
                type='button'
                className="btn btn-default"
                aria-label="Open calendar"
                title="Open calendar"
                onClick={() => toggleDatePicker(true)}
              >
                <i className="fa fa-calendar" />
              </button>
            </span>
          </FormField>
        </div>

        <DatePickerSelector
          selectedDate={arrivalDate}
          show={this.state.showDatePicker}
          disabledDays={this.isDateDisabled}
          onChange={onDateChange}
        />
      </React.Fragment>
    );
  }
}
