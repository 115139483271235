import React from 'react';
import { connect } from 'react-redux';

import { MainContainer, ParkComponent } from '../../components';
import { mapStateToProps, mapDispatchToProps } from '../../helpers';
import { merchActions, parksActions, requestActions } from '../../actions';
import './choose-park.css';


class ChoosePark extends React.Component {
  UNSAFE_componentWillMount() {
    document.body.className = '';
  }

  componentDidMount() {
    this.props.fetchParks();
    this.props.fetchMerch();
  }

  UNSAFE_componentWillUpdate() {
    document.body.className = '';
  }

  render() {
    const {
      merch,
      parks,
      selectedPark,
      requestError,
      resetRequestError,
    } = this.props;
    return (
      <MainContainer
        requestError={requestError}
        resetRequestError={resetRequestError}
        waitForLoad={[merch, parks]}
        className='choose-park'
      >
        <div className='row row-centered'>
          {parks && parks.map(park => (
            <ParkComponent
              key={park.get('id')}
              setPark={this.props.setPark}
              selectedPark={selectedPark}
              park={park}
            />
          ))}
        </div>
      </MainContainer>
    );
  }
}

export default connect(
  mapStateToProps('merch', 'parks', 'requestError', 'selectedPark'),
  mapDispatchToProps(merchActions, parksActions, requestActions),
)(ChoosePark);
