import PropTypes from 'prop-types';
import React from 'react';
import FormField from '../form-field/form-field';
import OverlayTooltip from '../overlay-tooltip';
import FormattedPrice from '../formatted-price/formatted-price';

import noimage from '../../assets/no-image.png';

export default class MerchItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    merchItem: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isEnabled: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    isEnabled: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      showDescription: true,
      showTooltip: false,
    };
  }

  handleImageLoaded() {
    const imageHeight = this.image.clientHeight;
    const descriptionHeight = this.descriptionTag ? this.descriptionTag.offsetHeight : 0;
    const isBiggerThanImage = descriptionHeight > imageHeight;

    this.setState({
      showTooltip: isBiggerThanImage,
      showDescription: !isBiggerThanImage,
    });
  }

  handleImageErrored() {
    this.setState({
      showTooltip: false,
      showDescription: true,
    });
  }

  updateQty(variant, qty) {
    let { merchItem } = this.props;

    if (merchItem === variant) {
      merchItem = merchItem.set('qty', Math.min(qty, merchItem.get('stock')));
    } else {
      merchItem = merchItem.update('variants', variants =>
        variants.map((v) => (v === variant ? v.set('qty', Math.min(qty, v.get('stock'))) : v)));
    }

    this.props.onChange(merchItem);
  }

  renderQty = (variant) => {
    const { merchItem, isEnabled, mobileView } = this.props;
    const qty = variant.get('qty', 0);
    const stock = variant.get('stock');

    return (
      <div className='number-ticket'>
        <div className='input-group'>
          <div className='input-group-btn'>
            <button
              className={`btn btn-default ${mobileView && 'btn-lg'}`}
              disabled={qty === 0}
              aria-label={`decrement ${merchItem.get('name')} quantity`}
              onClick={() => this.updateQty(variant, qty - 1)}
            >
              <i className='fa fa-minus' aria-hidden='true' />
            </button>
          </div>
          <FormField
            aria-label={`quantity for ${merchItem.get('name')}, up to ${stock}`}
            type='number' name={merchItem.get('name')}
            min={0} max={stock}
            value={qty.toString()}
            title={`Up to ${stock}`}
            disabled={!isEnabled}
            className={`${mobileView && 'input-lg'}`}
            onChange={value => this.updateQty(variant, window.parseInt(value))}
          />
          <div className='input-group-btn'>
            <button
              className={`btn btn-default ${mobileView && 'btn-lg'}`}
              disabled={qty >= stock}
              title={`Up to ${stock}`}
              aria-label={`increment ${merchItem.get('name')} quantity`}
              onClick={() => this.updateQty(variant, qty + 1)}
            >
              <i className='fa fa-plus' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderPriceAndQty = (variants, isSmall) => {
    const { merchItem, mobileView } = this.props;

    const list = variants.size > 0 ? variants : [merchItem];

    return (
      <div className="variants">
        {list.map((variant, index) => (
          <div key={index} className={`flex-box price-qty-content variant ${isSmall ? 'small-device' : 'big-device '}`}>
            <div className='center-cell ticket-price'>
              <strong>{variants.size > 0 && variant.get('name')}</strong>
            </div>
            <div className={`ticket-qty ${mobileView && 'touch-screen-mode'}`}>
              {this.renderQty(variant)}
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { merchItem, className } = this.props;
    const { showDescription, showTooltip } = this.state;
    const description = merchItem.get('short_description');
    const variants = merchItem.get('variants');

    return (
      <div className={`flex-box ${className}`}>
        <div className='flex-box pass-type-content'>
          <div className='ticket-image-wrapper'>
            <img
              className='ticket-image'
              onLoad={evt => this.handleImageLoaded(evt)}
              onError={evt => this.handleImageErrored(evt)}
              ref={(image) => { this.image = image; }}
              src={merchItem.get('thumbnail') || noimage}
              alt=''
            />
          </div>
          <div className='flex-box pass-type-description'>
            <span className='description-text'>
              <strong className='ticket-name'>
                <span>{merchItem.get('name')}</span>
                {showTooltip && description && (
                  <OverlayTooltip tooltip={description} id={`description_${merchItem.get('id')}`} />
                )}
              </strong>
              {description && (
                <small
                  ref={(descriptionTag) => { this.descriptionTag = descriptionTag; }}
                  className={showDescription ? '' : 'hidden'}
                >
                  {description}
                </small>
              )}
              <FormattedPrice value={merchItem.get('price')} />
            </span>
            {this.renderPriceAndQty(variants, true)}
          </div>
        </div>
        {this.renderPriceAndQty(variants)}
      </div>
    );
  }
}
