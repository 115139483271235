import { createSimpleAction } from '../helpers';

const resetBillingTotals = createSimpleAction(() => ({
  type: 'RESET_BILLING_TOTALS',
}));

const updateBillingInformation = createSimpleAction(billingInformation => ({
  type: 'UPDATE_BILLING_INFORMATION',
  payload: billingInformation,
}));

const updateTicketCost = createSimpleAction(ticketCostInformation => ({
  type: 'UPDATE_TICKET_COST_INFORMATION',
  payload: ticketCostInformation,
}));

export default {
  resetBillingTotals,
  updateBillingInformation,
  updateTicketCost,
};
