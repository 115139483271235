import React from 'react';
import PropTypes from 'prop-types';

import { Typeahead } from 'react-bootstrap-typeahead';
import OverlayTooltip from '../overlay-tooltip';

import './typehead-selector.css';

export default class TypeaheadSelector extends React.Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    selectedValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    tooltip: PropTypes.node,
    refInput: PropTypes.func,
    inputProps: PropTypes.object,
  };

  static defaultProps = {
    required: false,
    tooltip: null,
    inputProps: {},
    refInput: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      resetValue: false,
      menuVisibility: false,
    };
  }

  render() {
    const { resetValue, menuVisibility } = this.state;
    const {
      name,
      label,
      helpText,
      options,
      selectedValue,
      tooltip,
      refInput,
      inputProps,
    } = this.props;
    const value = resetValue ? '' : selectedValue;
    const isEmpty = v => v == null || v === '';

    let validationState = 'success';
    if (this.props.required && isEmpty(value)) {
      validationState = 'error';
    }
    return (
      <div className={`form-group has-${validationState}`}>
        {label && (
          <label htmlFor={name}>
            {label}
            &nbsp;
            {tooltip && <OverlayTooltip tooltip={tooltip} id={`tt_${name}`} />}
          </label>
        )}
        <Typeahead
          refInput={refInput}
          id={name}
          // name={name}
          inputProps={{
            name,
            ...inputProps,
          }}
          options={options}
          onChange={(selectedOption) => {
            this.setState({ selectedOption, menuVisibility: false });
          }}
          selected={options.filter(({ id }) => id === value)}
          onMenuToggle={(isOpen) => {
            if (isOpen) {
              this.setState({
                resetValue: true,
                selectedValue,
              });
            } else {
              this.setState({ resetValue: false });
              if (this.state.selectedOption && this.state.selectedOption.length !== 0) {
                this.props.onChange(this.state.selectedOption.length ?
                  this.state.selectedOption[0].id : null, name);
              } else {
                this.props.onChange(this.state.selectedValue, name);
              }
            }
          }}
          onBlur={() => this.setState({ menuVisibility: false })}
          onFocus={() => this.setState({ menuVisibility: true })}
          open={menuVisibility}
        />
        {helpText && <span className='help-block'>{helpText}</span>}
      </div>
    );
  }
}
