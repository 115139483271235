import React from 'react';
import PropTypes from 'prop-types';

import { roundTo, isIntNumber } from '../../helpers';

export default class OrderConfirmation extends React.Component {
  static propTypes = {
    ticketTypes: PropTypes.array.isRequired,
    arrivalInformation: PropTypes.object.isRequired,
    totalAmount: PropTypes.number.isRequired,
    totalDiscount: PropTypes.number.isRequired,
  };

  render() {
    const {
      ticketTypes,
      arrivalInformation,
      totalAmount,
      totalDiscount,
    } = this.props;
    const hasTotalDiscount = totalAmount !== totalDiscount;
    return (
      <div className='row'>
        <h2>Confirm your order</h2>
        <ul className='list-group kakadu-key-value-block col-lg-12'>
          <li className='list-group-item'>
            <div className='list-group-subtitle'>Arrival date</div>
            <span>{arrivalInformation.get('arrivalDate').format('DD MMMM, YYYY')}</span>
          </li>
          {ticketTypes
            .filter((tt) => {
              const count = tt.get('count', 0);
              return isIntNumber(count) && window.parseInt(count) > 0;
            })
            .map((tt) => {
              const discounted = tt.get('price_discounted');
              const price = tt.get('price_retail');
              const count = window.parseInt(tt.get('count'));
              const hasDiscount = discounted !== price;
              return (
                <li className='list-group-item' key={tt.get('id')}>
                  <div className='list-group-subtitle'>{ tt.get('name') }</div>
                  {
                    hasDiscount &&
                    (
                      <small>
                        ${roundTo(discounted)} x {count}
                      </small>
                    )
                  }
                  {
                    hasDiscount &&
                    (
                      <small>
                        &nbsp;with&nbsp;
                        {
                          tt.has('discount_percent') && (
                            <span>{ tt.get('discount_percent') }%&nbsp;</span>
                          )
                        }
                        {
                          tt.has('discount_amount') && (
                            <span>{ tt.get('discount_percent') }&nbsp;</span>
                          )
                        }
                        &nbsp;commission
                      </small>
                    )
                  }
                  {
                    !hasDiscount &&
                    (
                      <small>${roundTo(price)} x {count}</small>
                    )
                  }
                </li>
              );
            })
          }
          <li className='list-group-item'>
            <div className='list-group-subtitle'>Total</div>
            $
            {
              hasTotalDiscount ?
                (
                  <span>{ roundTo(totalDiscount) }</span>
                ) : (
                  <span>{ roundTo(totalAmount) }</span>
                )
            }
          </li>
        </ul>
      </div>
    );
  }
}
