const parseJsonErrors = (jsonData) => {
  const fields = {
    // party fields
    season: 'Season',
    email: 'Email',
    first_name: 'First name',
    last_name: 'Last name',
    country: 'Country',
    postcode: 'Postcode',
    phone: 'Phone',
    vehicle: 'Vehicle',
    arrival_date: 'Arrival Date',
    days_in_the_park: 'Days in the park',
    passes: 'Passes Info',
    // passes info
    pass_type_id: 'Pass Type',
    count: 'Ticket count',
    contact_data: 'Contacts',
  };

  const excludedFields = ['code', 'title'];

  const format = obj => (
    Object.keys(obj)
      .filter(key => excludedFields.indexOf(key) === -1)
      .map((key) => {
        const field = fields[key];
        let value = obj[key];
        if (Array.isArray(value)) {
          value = value.map((item) => {
            if (item instanceof Object) {
              return format(item);
            }
            return item;
          }).join('\n');
        } else if (value instanceof Object) {
          value = `${format(value)}`;
        }
        if (!field) {
          return value;
        }
        return `\n${field}: ${value}`;
      })
      .join('')
  );
  return format(jsonData);
};

const requestError = (state = null, action) => {
  if (action.type.indexOf('FAIL') !== -1) {
    if (action.payload instanceof TypeError) {
      return { errorType: 'NO_CONNECTION' };
    } else if (action.payload && action.payload.response) {
      const { status } = action.payload.response;
      if (status == 429) {
        return { errorType: 'RECAPTCHA_ERROR' };
      }
      if (status >= 500) {
        return { errorType: 'SERVER_ERROR' };
      }
      return {
        errorType: 'VALIDATION_ERROR',
        error: parseJsonErrors(action.payload.jsonData),
      };
    }
  }

  switch (action.type) {
    case 'RESET_REQUEST_ERROR':
      return null;
    case 'INVALID_START_DATE':
      return {
        errorType: action.type,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default requestError;
