import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap-modal';
import DayPicker from 'react-day-picker';
import { today } from '../../helpers';
import './datepicker.css';

// import Modal from '../modal/modal';

const Navbar = ({
  previousMonth,
  onNextClick,
  onPreviousClick,
  className,
  localeUtils,
}) => {
  const months = localeUtils.getMonths();
  const currentDate = new Date(previousMonth.valueOf());
  currentDate.setMonth(previousMonth.getMonth() + 1);
  return (
    <div className={className}>
      <div className='datepicker-header'>
        <button
          className='btn-month btn-prev'
          aria-label='previous month'
          onClick={() => onPreviousClick()}
        />
        <div className='text-center'>
          <div className='current-month'>{months[currentDate.getMonth()]}</div>
          <div className='current-year'>{currentDate.getFullYear()}</div>
        </div>
        <button
          className='btn-month btn-next'
          aria-label='next month'
          onClick={() => onNextClick()}
        />
      </div>
    </div>
  );
};


Navbar.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  localeUtils: PropTypes.object.isRequired,
  previousMonth: PropTypes.object.isRequired,
};

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 10, 11);

const YearMonthFrom = ({ date, localeUtils, onChange }) => {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i = i + 1) {
    years.push(i);
  }

  const handleChange = (e) => {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className='DayPicker-Caption'>
      <div className='col-xs-6'>
        <select name='month' onChange={handleChange} value={date.getMonth()}>
          {months.map((month, i) => (
            <option value={i} key={month}>
              {month}
            </option>
          ))}
        </select>
      </div>

      <div className='col-xs-6'>
        <select name='year' onChange={handleChange} value={date.getFullYear()}>
          {years.map(year => (
            <option value={year} key={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </form>
  );
};

export default class DatePickerSelector extends React.Component {
  static propTypes = {
    selectedDate: PropTypes.object,
    show: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedDate: today(),
  };

  constructor(props) {
    super(props);

    const currentMonth = today().toDate();
    this.state = {
      month: props.selectedDate ? props.selectedDate.toDate() : currentMonth,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedDate) {
      this.setState(() => ({ month: nextProps.selectedDate.toDate() }));
    }
  }

  close() {
    this.props.onChange();
  }

  handleYearMonthChange = (month) => {
    this.setState({ month });
  };

  handleChange(date, modifiers = {}) {
    if (modifiers.disabled) {
      return;
    }
    const selectedDate = moment(date);
    if (today().isSameOrBefore(selectedDate)) {
      this.props.onChange(moment(date));
    }
  }

  render() {
    const { selectedDate, disabledDays } = this.props;
    const close = () => this.close();
    return (
      <Modal show={this.props.show} onHide={close}>
        <Modal.Body>
          <DayPicker
            locale='au'
            month={this.state.month}
            fromMonth={fromMonth}
            toMonth={toMonth}
            navbarElement={Navbar}
            captionElement={({ date, localeUtils }) => (
              <YearMonthFrom
                date={date}
                localeUtils={localeUtils}
                onChange={this.handleYearMonthChange}
              />
            )}
            disabledDays={disabledDays || { before: today().toDate() }}
            onDayClick={(date, modifiers) => this.handleChange(date, modifiers)}
            selectedDays={selectedDate ? selectedDate.toDate() : null}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className='btn-group'>
            <button
              className='btn btn-info'
              type='button'
              onClick={() => this.handleChange(today().toDate())}
            >
              Today
            </button>
            <button className='btn' type='button' onClick={close}>Close</button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
