import PropTypes from 'prop-types';
import React from 'react';
import { isIntNumber } from '../../helpers';
import FormField from '../form-field/form-field';
import OverlayTooltip from '../overlay-tooltip';
import FormattedPrice from '../formatted-price/formatted-price';
import './ticket-type.css';

import noimage from '../../assets/no-image.png';

export default class TicketType extends React.Component {
  /*
  Draw the ticket type choice row (per each ticket type available to purchase).
  */
  static propTypes = {
    className: PropTypes.string,
    // now many tickets may be purchased till the party-wide limit is hit
    // (which is around 100 for FIT, so we usually have values 90-100 here)
    partyWideTicketLimitRemains: PropTypes.number,
    ticketType: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isEnabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: null,
    partyWideTicketLimitRemains: 100,
  };

  constructor(props) {
    super(props);
    this.state = {
      showDescription: true,
      showTooltip: false,
    };
  }

  handleImageLoaded() {
    const imageHeight = this.image.clientHeight;
    const descriptionHeight = this.descriptionTag ? this.descriptionTag.offsetHeight : 0;
    const isBiggerThanImage = descriptionHeight > imageHeight;

    this.setState({
      showTooltip: isBiggerThanImage,
      showDescription: !isBiggerThanImage,
    });
  }

  handleImageErrored() {
    this.setState({
      showTooltip: false,
      showDescription: true,
    });
  }

  updateTicketCount(val) {
    let validatedValue = val;
    if (validatedValue < 0) {
      return;
    }
    const { ticketType } = this.props;
    let maxQty = ticketType.get('validation_rules').get('general').get('max_qty');
    if (!maxQty && maxQty !== 0) maxQty = this.props.partyWideTicketLimitRemains;
    const ticketTypeMaxQty = maxQty;
    const partyWideTicketLimitRemains = (
      ticketType.get('count') || 0
    ) + this.props.partyWideTicketLimitRemains;

    if (validatedValue > partyWideTicketLimitRemains) {
      validatedValue = partyWideTicketLimitRemains;
    }

    if (validatedValue > ticketTypeMaxQty) {
      validatedValue = ticketTypeMaxQty;
    }

    this.props.onChange(ticketType.set('count', validatedValue));
  }

  renderPriceAndQty = (isSmall) => {
    const {
      ticketType,
      partyWideTicketLimitRemains,
      isEnabled,
      mobileView,
    } = this.props;

    let maxQty = ticketType.get('validation_rules').get('general').get('max_qty');
    if (!maxQty && maxQty !== 0) maxQty = partyWideTicketLimitRemains;
    const maxTicketsCount = Math.min(
      maxQty,
      partyWideTicketLimitRemains,
    );

    const count = ticketType.get('count', 0);
    const incrementCount = isIntNumber(count) ? window.parseInt(count) : 0;

    return (
      <div className={`flex-box price-qty-content ${isSmall ? 'small-device' : 'big-device '}`}>
        <div className='center-cell ticket-price'>
          <FormattedPrice value={ticketType.get('price_retail')} />
        </div>
        <div className={`ticket-qty ${mobileView && 'touch-screen-mode'}`}>
          <div className='number-ticket'>
            <div className='input-group'>
              <div className='input-group-btn'>
                <button
                  className={`btn btn-default ${mobileView && 'btn-lg'}`}
                  disabled={incrementCount === 0}
                  aria-label={`decrement ${ticketType.get('name')} pass quantity`}
                  onClick={() => this.updateTicketCount(incrementCount - 1)}
                >
                  <i className='fa fa-minus' aria-hidden='true' />
                </button>
              </div>
              <FormField
                aria-label={`quantity for ${ticketType.get('name')} tickets, up to ${maxTicketsCount}`}
                type='number' name={ticketType.get('name')}
                min={0} max={maxTicketsCount}
                value={count.toString()}
                title={`Up to ${maxTicketsCount}`}
                disabled={!isEnabled}
                className={`${mobileView && 'input-lg'}`}
                onChange={value => this.updateTicketCount(window.parseInt(value))}
              />
              <div className='input-group-btn'>
                <button
                  className={`btn btn-default ${mobileView && 'btn-lg'}`}
                  disabled={maxTicketsCount === 0}
                  title={`Up to ${maxTicketsCount}`}
                  aria-label={`increment ${ticketType.get('name')} pass quantity`}
                  onClick={() => this.updateTicketCount(incrementCount + 1)}
                >
                  <i className='fa fa-plus' aria-hidden='true' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      ticketType,
      // partyWideTicketLimitRemains,
      className,
      // isEnabled,
      // mobileView,
    } = this.props;

    // const maxTicketsCount = Math.min(
    //   ticketType.get('validation_rules')
    //     .get('general')
    //     .get('max_qty') || partyWideTicketLimitRemains,
    //   partyWideTicketLimitRemains,
    // );

    const { showDescription, showTooltip } = this.state;
    // const count = ticketType.get('count', 0);
    const description = ticketType.get('description');
    // const incrementCount = isIntNumber(count) ? window.parseInt(count) : 0;
    return (
      <div className={`flex-box ${className}`}>
        <div className='flex-box pass-type-content'>
          <div className='ticket-image-wrapper'>
            <img
              className='ticket-image'
              onLoad={evt => this.handleImageLoaded(evt)}
              onError={evt => this.handleImageErrored(evt)}
              ref={(image) => { this.image = image; }}
              src={ticketType.get('image') || noimage}
              alt=''
            />
          </div>
          <div className='flex-box pass-type-description'>
            <span className='description-text'>
              <strong className='ticket-name'>
                <span>{ticketType.get('name')}</span>
                {showTooltip && description && (
                  <OverlayTooltip tooltip={description} id={`description_${ticketType.get('name')}`} />
                )}
              </strong>
              {description && (
                <small
                  ref={(descriptionTag) => { this.descriptionTag = descriptionTag; }}
                  className={showDescription ? '' : 'hidden'}
                >
                  {description}
                </small>
              )}
            </span>
            {
              // Only for small screen
              this.renderPriceAndQty(true)
            }
          </div>
        </div>

        {this.renderPriceAndQty()}
      </div>
    );
  }
}
