import PropTypes from 'prop-types';
import React from 'react';

import FormField from '../form-field/form-field';
import TypeaheadSelector from '../typeahead-selector/typeahead-selector';
import {
  EmailValidator, MaxLengthValueValidator, MinLengthValueValidator, NTPostcodeValidator,
  NumberValidator, validateFindRequiredFields, validatePassRequiredFields,
} from '../validator/validator';
import './contact-info.css';


export default class ContactInfo extends React.Component {
  static propTypes = {
    ticket: PropTypes.object.isRequired,
    countries: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onChange(value, property) {
    let updatedTicketData = this.props.ticket.set(property, value);
    if (typeof this.rules.postcode === 'string' && this.rules.postcode !== 'nt') {
      updatedTicketData = updatedTicketData.set('postcode', this.rules.postcode);
    }
    if (typeof this.rules.country === 'string') {
      updatedTicketData = updatedTicketData.set('country', this.rules.country);
    }
    if (property === 'country' && value !== 'AU') {
      updatedTicketData = updatedTicketData.set('postcode', '');
    }
    this.props.onChange(updatedTicketData);
  }

  checkFocus = () => {
    const { errors, ticket } = this.props;
    const field = validateFindRequiredFields(ticket.toJS(), errors);
    if (field) {
      if (this[field] && this[field].focus) {
        this[field].scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          this[field].focus();
        }, 500);
        return true;
      }
    }
    return false;
  };

  render() {
    // Note: errors List() contains only filled fields errors (wrong email, etc)
    // empty fields which are required are validated by validatePassRequiredFields
    const {
      ticket, countries, className, errors,
    } = this.props;
    const options = countries.toJS().map(({ code, name }) => ({ id: code, label: name }));
    const country = ticket.get('country');
    const rules = ticket.get('ticketValidationRules').toJS();
    this.rules = rules;
    const postcode = !!rules.postcode && country === 'AU';
    const ticketRandomId = window.parseInt(Math.random() * 1000000);

    let postCodeValidators = [];
    if (postcode) {
      postCodeValidators = [
        new MinLengthValueValidator(4),
        new MaxLengthValueValidator(4),
        new NumberValidator(),
      ];
      if ((typeof rules.postcode === 'string' && /nt/i.test(rules.postcode))) {
        postCodeValidators.push(new NTPostcodeValidator());
      }
    }

    // highlight the row if any errors are present (so users aren't confused anymore)
    const highlightRow = errors.size > 0 || !validatePassRequiredFields(ticket.toJS());
    const classNameWithValidation = `${className} ${
      highlightRow ? 'bg-slight-warning' : ''
    }`;

    return (
      <div className={`row ${classNameWithValidation}`}>
        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
          <h2>{ ticket.get('passType') }</h2>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4 col-lg-2 form-field-wrapper'>
          <FormField
            refInput={(inputComponent) => { this.firstName = inputComponent; }}
            required={rules.first_last_name}
            name='firstName'
            label={`First name${rules.first_last_name ? '*' : ''}`}
            tooltip='Initial or full first name'
            value={ticket.get('firstName')}
            validators={[new MinLengthValueValidator(1), new MaxLengthValueValidator(50)]}
            onChange={(...props) => this.onChange(...props)}
          />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4 col-lg-2 form-field-wrapper'>
          <FormField
            refInput={(inputComponent) => { this.lastName = inputComponent; }}
            required={rules.first_last_name}
            name='lastName'
            label={`Last name${rules.first_last_name ? '*' : ''}`}
            tooltip='Full last name'
            value={ticket.get('lastName')}
            validators={[new MinLengthValueValidator(2), new MaxLengthValueValidator(50)]}
            onChange={(...props) => this.onChange(...props)}
          />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4 col-lg-2 form-field-wrapper'>
          <TypeaheadSelector
            name='country'
            label={`Country${rules.country ? '*' : ''}`}
            options={options}
            selectedValue={country}
            onChange={(value, name) => this.onChange(value, name)}
            inputProps={{
              'aria-label': 'country of residence',
            }}
          />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4 col-lg-2 form-field-wrapper'>
          <FormField
            refInput={(inputComponent) => { this.postcode = inputComponent; }}
            required={postcode}
            disabled={ticket.get('country') !== 'AU'}
            name='postcode'
            label={`Postcode${postcode ? '*' : ''}`}
            validators={postCodeValidators}
            value={
              typeof rules.postcode === 'string' && rules.postcode !== 'nt'
                ?
                rules.postcode
                :
                ticket.get('postcode')
            }
            onChange={(...props) => this.onChange(...props)}
          />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4 col-lg-2 form-field-wrapper'>
          <FormField
            refInput={(inputComponent) => { this.phone = inputComponent; }}
            required={rules.phone}
            name='phone'
            componentClass='phone'
            label={`Mobile${rules.phone ? '*' : ''}`}
            tooltip='We will SMS a mobile version of your pass to you'
            secondTooltip={!ticket.get('ticketValidationRules').get('phoneValid')
              ? 'The phone number you have entered may be invalid. You may still proceed if it looks correct'
              : null}
            value={ticket.get('phone')}
            flagsPath='/static/flag-icon-css/flags/4x3/'
            indicateInvalid
            onChange={(...props) => this.onChange(...props)}
          />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4 col-lg-2 form-field-wrapper'>
          <FormField
            refInput={(inputComponent) => { this.vehicle = inputComponent; }}
            required={rules.rego}
            name='vehicle'
            label={`Vehicle${rules.rego ? '*' : ''}`}
            tooltip='Enter "HIRE CAR" if you are hiring a vehicle and do not know the registration.'
            value={ticket.get('vehicle')}
            onChange={(...props) => this.onChange(...props)}
          />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-6 col-lg-4 form-field-wrapper'>
          <FormField
            refInput={(inputComponent) => { this.email = inputComponent; }}
            required={rules.email}
            name='email'
            type='email'
            label={`Email${rules.email ? '*' : ''}`}
            tooltip='We will email your pass to you'
            validators={[new EmailValidator()]}
            value={ticket.get('email')}
            onChange={(...props) => this.onChange(...props)}
          />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-6 col-lg-8 form-field-wrapper'>
          <label htmlFor={`id_pass_${ticketRandomId}`} className={ticket.get('subscribe') ? 'bold' : 'thin'}>
            <span>
              <input
                name='isGoing'
                type='checkbox'
                id={`id_pass_${ticketRandomId}`}
                checked={ticket.get('subscribe')}
                onChange={evt => this.onChange(evt.target.checked, 'subscribe')}
                tabIndex={-1}
              />
              &nbsp;
              I would like to receive more information about&nbsp;
              Australia&lsquo;s national parks from Parks Australia.
            </span>
          </label>
        </div>
      </div>
    );
  }
}
