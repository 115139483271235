import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { roundTo } from '../../helpers/utils';

import './formatted-price.css';

const FormattedPrice = ({
  value,
  to,
  fullDiscountInEffect,
}) => (
  fullDiscountInEffect && value > 0
    ? (
      <Fragment>
        <strike className='formatted-price'>${roundTo(value, to)}</strike>
        <strong className='formatted-price'>${roundTo(0, to)}</strong>
      </Fragment>
    ) : (
      <strong className='formatted-price'>${roundTo(value, to)}</strong>
    )
);

const mapStateToProps = state => ({
  fullDiscountInEffect: state.arrivalInformation.get('fullDiscountInEffect'),
});

export default connect(mapStateToProps)(FormattedPrice);
