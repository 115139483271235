import { List, is } from 'immutable';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import queryString from 'querystring';

import {
  arrivalInformationActions, billingInformationActions, countriesActions, errorActions,
  merchActions, parksActions, partyActions, requestActions, seasonsActions, ticketTypesActions,
} from '../../actions';
import {
  ArrivalAdditionForm, ArrivalForm, BillingContact, MainContainer, OrderConfirmation,
  SeasonInformation, TicketChoose, MerchChoose,
} from '../../components';
import {
  getExtendedValidationRules, getTicketTypeValidationRules, performNisCheck, /* validateBillingContact, */
  validateRequiredFields,
} from '../../components/validator/validator';
import { mapDispatchToProps, mapStateToProps } from '../../helpers';

import widgetConfig from '../../widgetConfig';
import FormattedPrice from '../../components/formatted-price/formatted-price';
import './create-party.css';
import { countTruthyKey, pick, parseDisabledDates } from '../../helpers/utils';

const createPossibleDayOptions = (possibleDays, mobileView) => {
  const days = mobileView ? [] : [
    { value: null, name: '--------------' },
  ];

  return days.concat(...new Array(possibleDays)
    .fill(0)
    .map((item, idx) => ({ value: idx + 1, name: idx + 1 })),
  );
};

const getParkHeader = (park, mobileView) => {
  let header = '';
  if (park) {
    header = mobileView ? (
      `${park.get('name')} National Park`
    ) : (
      `Welcome to ${park.get('name')} National Park`
    );
  }
  return header;
};


const tranformToOptions = options => (
  options.map(option => ({ value: option, name: option }))
);


const clientPaymentOptions = tranformToOptions([
  'Cash',
  'EFTPOS',
  'Cheque',
  'Other',
]);

const chosenValidationRules = pick('first_last_name', 'country', 'postcode', 'phone', 'rego', 'email');

const parksClosure =
  Object.entries(widgetConfig.parksClosure || {})
    .reduce((memo, [slug, parkClosure]) => Object.assign(memo, {
      [slug]: {
        closureLink: parkClosure.closure_link,
        isDateClosed: parseDisabledDates(parkClosure.dates),
      },
    }), {});

class CreateParty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inMobileWidth: window.innerWidth < 768,
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleChangeResize);
    this.props.resetErrors();
  }

  componentDidMount() {
    const { merch, parks } = this.props;
    this.props.fetchCountries();
    if (!merch) {
      this.props.fetchMerch();
    }
    if (!parks) {
      this.props.fetchParks();
    }

    this.fillArrivalDateForRenewal();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      parks, seasons, arrivalInformation, requestError,
    } = nextProps;
    const { match: { params: { parkSlug } } } = nextProps;
    const park = parks && parks.size ? parks.find(p => p.get('slug') == parkSlug) : null;
    const isSameDates = this.props.arrivalInformation.get('arrivalDate')
      ? this.props.arrivalInformation.get('arrivalDate')
        .isSame(arrivalInformation.get('arrivalDate'), 'day')
      : false;
    const hasError = requestError && requestError.error;

    if (park && (park != this.state.park || !isSameDates)) {
      this.setState({ park });
      document.body.className = park.get('acronym');
      if ((!seasons || !isSameDates) && !hasError) {
        const arrivalDate = arrivalInformation.get('arrivalDate');
        if (arrivalDate) {
          this.props.fetchSeasons(park.get('id'), arrivalDate);
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const prevSeasons = prevProps.seasons && prevProps.seasons.map(v => v.get('id'));
    const nextSeasons = this.props.seasons && this.props.seasons.map(v => v.get('id'));

    if (!is(prevSeasons, nextSeasons)) {
      this.props.resetBillingTotals();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleChangeResize);
  }

  onMissingClick = () => {
    if (this.billingContact.checkFocus()) return;
    if (this.arrivalAddition) {
      this.arrivalAddition.checkFocus();
    }
  };

  onChange(value, property) {
    let data = this.props.arrivalInformation;

    if (property == 'daysInPark' && Number.isNaN(parseInt(value, 10))) {
      data = data.set(property, null);
    } else {
      data = data.set(property, value);
    }
    data = data.set(
      'billingValidationRules', this.getBillingInformationRules(),
    );

    this.props.updateArrivalInformation(data);
  }

  getBillingInformationRules() {
    const {
      ticketTypes, widgetType, billingContact, arrivalInformation,
    } = this.props;
    const currentCountry = billingContact.get('country') || 'AU';
    const billingValidationRules = getExtendedValidationRules(
      ticketTypes,
      widgetType,
      currentCountry,
      arrivalInformation.get('fullDiscountInEffect'),
    );

    return billingValidationRules;
  }

  fillArrivalDateForRenewal() {
    const { location: { search }, raiseInvalidStartDateError } = this.props;

    if (!search) return;

    const params = queryString.parse(search.substring(1));
    if (params.start_date) {
      const startDate = moment(params.start_date);
      if (startDate.isValid()) {
        const parkClosure = parksClosure[this.props.match.params.parkSlug];
        if (parkClosure && parkClosure.isDateClosed(startDate)) {
          if (parkClosure.closureLink) {
            window.location.href = parkClosure.closureLink;
          } else {
            this.onChange(null, 'arrivalDate');
            raiseInvalidStartDateError();
          }
        } else {
          this.onChange(startDate, 'arrivalDate');
        }
      } else {
        this.onChange(null, 'arrivalDate');
        raiseInvalidStartDateError();
      }

      this.props.fetchParks();
    }
  }

  handleChangeResize = () => {
    this.setState({
      inMobileWidth: window.innerWidth < 768,
    });
  };

  prepareParty() {
    const {
      ticketTypes,
      billingContact,
      arrivalInformation,
      seasons,
    } = this.props;

    const billingValidationRules = this.getBillingInformationRules();

    const { park } = this.state;
    let tickets = [];

    const categoriesIDs = park.get(
      'pass_type_categories',
    ).reduce(
      (acc, item) => { acc.push(item.get('id')); return acc; }, [],
    );

    ticketTypes.forEach((ticketType) => {
      const count = ticketType.get('count');
      const desiredSortOrder = (categoriesIDs.indexOf(
        ticketType.get('category'),
      ) * 100) + ticketType.get('order');
      for (let i = 0; i < count; i = i + 1) {
        tickets.push({
          id: ticketType.get('id'),
          passTypeId: ticketType.get('id'),
          passType: ticketType.get('name'),
          order: desiredSortOrder,
          ticketValidationRules: {
            ...getTicketTypeValidationRules(ticketType),
            phoneValid: true,
          },
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          subscribe: billingContact.get('subscribe'),
          country: billingContact.get('country'),
          postcode: billingContact.get('postcode'),
          vehicle: billingContact.get('vehicle'),
        });
      }
    });

    if (tickets.length) {
      tickets = tickets.sort(
        (a, b) => a.order - b.order,
      );

      let theSmartestTicketIndex = 0;
      let theSmartestTicketSmartness = countTruthyKey(
        chosenValidationRules(tickets[0].ticketValidationRules),
      );

      tickets.forEach((ticket, index) => {
        const thisTicketSmartness = countTruthyKey(
          chosenValidationRules(ticket.ticketValidationRules),
        );
        if (thisTicketSmartness > theSmartestTicketSmartness) {
          theSmartestTicketSmartness = thisTicketSmartness;
          theSmartestTicketIndex = index;
        }
      });

      tickets[theSmartestTicketIndex].ticketValidationRules.phoneValid = billingContact.get('phoneValid');
      tickets[theSmartestTicketIndex].firstName = billingContact.get('firstName');
      tickets[theSmartestTicketIndex].lastName = billingContact.get('lastName');
      tickets[theSmartestTicketIndex].phone = billingContact.get('phone');
      tickets[theSmartestTicketIndex].email = billingContact.get('email');
    }

    // calculate "days in the park" based on our validation rules
    // sometimes from direct field, sometimes by calculating from `Departure date`.
    // we assume that these fields will never be shown at the same time.
    let daysInPark = null;
    if (billingValidationRules.departure_date !== null) {
      const departure = moment(
        arrivalInformation.get('departureDate') || arrivalInformation.get('arrivalDate'),
      );
      daysInPark = (
        departure.diff(arrivalInformation.get('arrivalDate'), 'days') + 1
      ).toString();
    } else {
      daysInPark = arrivalInformation.get('daysInPark');
    }

    return {
      contactData: billingContact.toJS(),
      park: park.get('id'),
      parkSlug: park.get('slug'),
      arrivalDate: arrivalInformation.get('arrivalDate'),
      season: seasons.get(0).get('id'),
      tickets,
      daysInPark,
    };
  }

  assignPasses() {
    this.props.prepareParty(this.prepareParty());
    this.props.history.push(`${widgetConfig.url_prefix}/passes/assign-passes/`);
  }

  updateTicketCost(ticketTypes, merchItems) {
    this.props.updateTicketCost(
      ticketTypes.reduce((totalInfo, ticketType) => {
        const count = ticketType.get('count', 0);
        const amount = count * window.parseFloat(ticketType.get('price_retail'));
        const discount = count * window.parseFloat(ticketType.get('price_discounted'));

        totalInfo.totalAmount += amount;
        totalInfo.totalDiscount += discount;
        totalInfo.totalTicketsAmount += amount;
        totalInfo.totalTicketsQty += count;

        return totalInfo;
      }, merchItems.reduce((totalInfo, merchItem) => {
        const qty = merchItem.get('qty', 0);
        const price = merchItem.get('price', 0);

        let totalQty = qty;
        let totalAmount = qty * price;

        merchItem.get('variants', []).forEach((variant) => {
          const variantQty = variant.get('qty', 0);

          totalQty += variantQty;
          totalAmount += variantQty * price;
        });

        totalInfo.totalAmount += totalAmount;
        totalInfo.totalMerchQty += totalQty;
        totalInfo.totalMerchAmount += totalAmount;

        return totalInfo;
      }, {
        totalAmount: 0,
        totalDiscount: 0,
        totalTicketsAmount: 0,
        totalTicketsQty: 0,
        totalMerchAmount: 0,
        totalMerchQty: 0,
      })),
    );
  }

  updateTicketType = (ticketType) => {
    const { ticketTypes, merch } = this.props;
    this.updateTicketCost(
      ticketTypes.map(v => (v.get('id') === ticketType.get('id') ? ticketType : v)),
      merch,
    );
    this.props.updateTicketType(ticketType);
  }

  updateMerchItem = (merchItem) => {
    const { ticketTypes, merch } = this.props;
    this.updateTicketCost(
      ticketTypes,
      merch.map(v => (v.get('id') === merchItem.get('id') ? merchItem : v)),
    );
    this.props.updateMerchItem(merchItem);
  }

  render() {
    // TODO: format it the better way
    const {
      match: { params: { categoryId, parkSlug } },
      billingContact,
      ticketTypes,
      seasons,
      arrivalInformation,
      countries,
      requestError,
      resetRequestError,
      merch: allMerch,
      parks,
    } = this.props;
    let { errors } = this.props;

    const { park } = this.state;
    const { widgetType, retailerType, showMerchSection } = widgetConfig;
    const season = seasons ? seasons.get(0) : null;
    const merch = allMerch && park ? allMerch.filter(v => v.get('park_id') == park.get('id')) : null;

    // fetch party-wide validation rules
    const currentCountry = billingContact.country || 'AU';
    const billingValidationRules = getExtendedValidationRules(
      ticketTypes,
      widgetType,
      currentCountry,
      arrivalInformation.get('fullDiscountInEffect'),
    );

    const isExtendedValidationPassed = validateRequiredFields(
      billingValidationRules, arrivalInformation, billingContact,
    );

    // validate NIS criteria only for non-parks
    if ((retailerType !== 'park') && !performNisCheck(ticketTypes)) {
      errors = errors.push({
        id: null,
        code: 'nis',
        description: `The order contains passes that can only be issued alongside other passes.`,
      });
    }

    // ATTENTION: this change may introduce a regression, but anyway validation on render looks
    // like a wrong decision. We raise re-validation in the BillingContract.componentDidMount now.
    // ------------------------------------------------------------------------------------
    // // we need that variable because we need to run validation if
    // // tickets set is changed, and by default it's run only on billing
    // // contact change. But validation rules may change.
    // // This is probably an ugly solution and may be improved bu some ReactJS specialist
    // const localErrors = validateBillingContact(
    //   billingContact.toJS(),
    //   billingValidationRules,
    //   'UPDATE_BILLING_INFORMATION',
    // );
    const arrivalDate = arrivalInformation.get('arrivalDate');
    const arrivalInfoErrors = arrivalInformation.get('errors');
    const isValid = isExtendedValidationPassed &&
      // localErrors.size == 0 &&
      errors.size === 0 &&
      billingContact.get('totalTicketsQty', 0) + billingContact.get('totalMerchQty', 0) > 0 &&
      (!arrivalInfoErrors || arrivalInfoErrors.size === 0);

    // get the list of categories, filtering Business
    // because we have 2 types of widgets now - only Business and only without Business
    // https://github.com/envris/nationalparks/issues/2448
    let categories = new List();
    (park ? park.get('pass_type_categories') : new List()).forEach((cat) => {
      if (widgetConfig.salesType === 'business' && cat.get('name').toLowerCase() !== 'business') {
        return; // ignore non-business category for business-only widget
      }
      if (widgetConfig.salesType === 'non-business' && cat.get('name').toLowerCase() === 'business') {
        return; // ignore business category for non-business-only widget
      }
      if (!ticketTypes.some(v => v.get('category') === cat.get('id'))) {
        return; // ignore category with no tickets (#8662)
      }
      categories = categories.push(cat);
    });

    if (categoryId && !categories.some(v => v.get('id') === categoryId)) {
      return <Redirect to={`${widgetConfig.url_prefix}/passes/${parkSlug}`} />;
    }

    const termsLink = 'entry-terms-and-conditions/';
    const selectedPaymentMethod = billingContact.get('clientPaymentMethod');
    const paymentOptions = clientPaymentOptions;

    // Check valid park
    let errorPark;
    if (parks && parks.size) {
      if (!parks.toJS().find(parkDetail => parkDetail.slug === parkSlug)) {
        errorPark = {
          errorType: 'PARK_NOT_FOUND',
        };
      }
    }

    // Get required fields
    const requiredFields = Object.keys(billingValidationRules)
      .filter((key) => {
        if (billingValidationRules[key]) {
          if (key === 'days_in_the_park') {
            return !arrivalInformation.get('daysInPark');
          } else if (key === 'first_last_name') {
            return !billingContact.get('firstName') || !billingContact.get('lastName');
          } else if (key === 'document_upload_required') {
            return false;
          } else if (key === 'postcode') {
            const country = billingContact.get('country');
            if (country === 'AU') {
              return !billingContact.get('postcode');
            }
            return false;
          } else if (key === 'departure_date') {
            return !arrivalInformation.get('departureDate');
          } else if (key === 'rego') {
            return !billingContact.get('vehicle');
          }
          return key !== 'client_payment_method' && !billingContact.get(key);
        }
        return false;
      })
      .map(key => key.replace(/[_]/g, ' ').replace(/\b\w/g, l => l.toUpperCase()));

    const totalAmount = billingContact.get('totalAmount');
    const hasMerch = showMerchSection && merch && merch.size > 0;

    // FIXME: too much logic and html mixed, unreadable
    // https://github.com/facebook/create-react-app/issues/8687
    return (
      <MainContainer
        history={this.props.history}
        requestError={requestError || errorPark}
        resetRequestError={resetRequestError}
        waitForLoad={[
          merch, park, billingContact, arrivalInformation, countries,
        ]}
        className='create-party'
      >
        {park && (
          <div className='form-heading'>
            <Link
              className='pull-left'
              to={`${widgetConfig.url_prefix}/passes/`}
              aria-label="Back"
            >
              <i className='fa fa-chevron-left' />
            </Link>
            <h1
              className='text-center'
              ref={(ref) => {
                if (ref && !this.welcomeFocused) {
                  // https://github.com/envris/nationalparks/issues/4971
                  ref.setAttribute('tabIndex', 0);
                  ref.style.outline = 'none';
                  ref.focus();
                  ref.onblur = () => ref.removeAttribute('tabindex');
                  this.welcomeFocused = true;
                }
              }}
            >
              {getParkHeader(park, widgetConfig.mobileView)}
            </h1>
            {!widgetConfig.mobileView && (
              <p className='help-text text-center display-linebreak'>
                {park.get('pos_message') || park.get('description')}
              </p>
            )}
          </div>
        )}
        <form className='arrival-form row flex-box'>
          <ArrivalForm
            arrivalInformation={arrivalInformation}
            billingValidationRules={billingValidationRules}
            defaultDaysInPark={park?.get('days_in_park')}
            parkClosure={parksClosure[parkSlug]}
            season={season}
            onChange={this.props.updateArrivalInformation}
          />
          {
            !this.state.inMobileWidth &&
            <ArrivalAdditionForm
              ref={(arrivalAddition) => { this.arrivalAddition = arrivalAddition; }}
              ticketTypes={ticketTypes}
              categoryId={categoryId}
              categories={categories}
              billingInformation={billingContact}
              errors={errors}
              mobileView={widgetConfig.mobileView}
              arrivalInformation={arrivalInformation}
              possibleDays={park ? createPossibleDayOptions(
                park.get('days_in_park_extended'), widgetConfig.mobileView,
              ) : []}
              billingValidationRules={billingValidationRules}
              onChange={this.props.updateArrivalInformation}
            />
          }
        </form>

        {(season && !widgetConfig.mobileView) && <SeasonInformation season={season} />}

        {(arrivalInformation.get('fullDiscountInEffect') && season) && (
          <p className='help-text text-center display-linebreak'>
            Park entry fees for Booderee, Kakadu and Uluru-Kata Tjuta National
            Parks have been waived between 16 March to 31 December 2020.
            Book your free Park Pass here.
          </p>
        )}

        {season && (
          <div className='row flex-box ticket-form-wrapper'>
            <div className='col-no-padding ticket-choose-wrapper'>
              {categories && ticketTypes && (
                <TicketChoose
                  season={season}
                  errors={errors}
                  defaultDaysInPark={park?.get('days_in_park')}
                  mobileView={widgetConfig.mobileView}
                  maxTicketCount={widgetConfig.maxTicketCount}
                  onChange={this.props.updateArrivalInformation}
                  widgetType={widgetType}
                  arrivalInformation={arrivalInformation}
                  possibleDays={park ? createPossibleDayOptions(
                    park.get('days_in_park_extended'), widgetConfig.mobileView,
                  ) : []}
                  billingInformation={billingContact}
                  categoryId={categoryId}
                  categories={categories}
                  parkSlug={parkSlug}
                  ticketTypes={ticketTypes}
                  billingValidationRules={billingValidationRules}
                  updateTicketType={this.updateTicketType}
                />
              )}
            </div>
            {hasMerch && (
              <>
                <div className='col-no-padding merch-choose-wrapper'>
                  <MerchChoose
                    billingInformation={billingContact}
                    merch={merch}
                    mobileView={widgetConfig.mobileView}
                    gridView={widgetConfig.gridView}
                    onChange={this.updateMerchItem}
                  />
                </div>
                <div className='col-no-padding merch-total-wrapper'>
                  <div className='row row-table row-table-bordered'>
                    <div className='result-table'>
                      <span className='col-no-padding'>
                        <h3>Total Retail Price:</h3>
                      </span>
                      <span className='col-no-padding total-price-number'>
                        <h3><FormattedPrice value={totalAmount} /></h3>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
            {(billingContact.get('totalTicketsQty', 0) + billingContact.get('totalMerchQty', 0) > 0) && (
              <div className='col-no-padding billing-contact-wrapper'>
                {ticketTypes && (<BillingContact
                  ref={(billingComponent) => { this.billingContact = billingComponent; }}
                  errors={errors}
                  mobileView={widgetConfig.mobileView}
                  countries={countries}
                  termsLink={termsLink}
                  billingContact={billingContact}
                  billingValidationRules={billingValidationRules}
                  onChange={this.props.updateBillingInformation}
                />)}
                {
                  this.state.inMobileWidth &&
                  <form className='arrival-form row'>
                    <ArrivalAdditionForm
                      ref={(arrivalAddition) => { this.arrivalAddition = arrivalAddition; }}
                      ticketTypes={ticketTypes}
                      categoryId={categoryId}
                      categories={categories}
                      billingInformation={billingContact}
                      errors={errors}
                      mobileView={widgetConfig.mobileView}
                      arrivalInformation={arrivalInformation}
                      possibleDays={park ? createPossibleDayOptions(
                        park.get('days_in_park_extended'), widgetConfig.mobileView,
                      ) : []}
                      billingValidationRules={billingValidationRules}
                      onChange={this.props.updateArrivalInformation}
                      fullWidthInMobile
                    />
                  </form>
                }

                {!isValid ?
                  (
                    <div className='col-xs-12'>

                      {requiredFields && requiredFields.length > 0 && (
                        <div className='alert alert-warning'>
                          <p>
                            Please fill all required fields: {requiredFields.join(', ')}
                          </p>
                        </div>
                      )}

                      {errors && errors.size > 0 && (
                        <div className='alert alert-warning'>
                          {errors.map((error, id) => (<p key={id}>{error.description}</p>))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className='col-xs-12'>
                      <div className='alert alert-success'>
                        Proceed to checkout below
                      </div>
                    </div>
                  )
                }
                {
                  (widgetConfig.widgetType === 'retail' && !arrivalInformation.get('fullDiscountInEffect')) && (
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                      <div className='form-group'>
                        <label className='block-label'>Client payment method</label>
                        {paymentOptions.map(paymentMethod => (
                          <button
                            key={paymentMethod.value}
                            type='button'
                            className={`btn btn-right-space ${
                              selectedPaymentMethod === paymentMethod.value ?
                                'btn-primary' :
                                'btn-default'
                            }`}
                            onClick={() => this.props.updateBillingInformation(
                              billingContact.set('clientPaymentMethod', paymentMethod.value),
                            )}
                          >
                            {paymentMethod.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  )
                }
                {
                  widgetConfig.orderConfirmationPage &&
                  (
                    <div className='col-xs-12 col-md-6 col-lg-6 col-md-offset-3 col-lg-offset-3 col-no-padding'>
                      <OrderConfirmation
                        arrivalInformation={arrivalInformation}
                        ticketTypes={ticketTypes}
                        totalAmount={billingContact.get('totalAmount')}
                        totalDiscount={billingContact.get('totalDiscount')}
                      />
                    </div>
                  )
                }
                <div className='col-xs-12'>
                  <div className='btn-assign-wrapper'>
                    <button
                      onClick={() => {
                        if (isValid) {
                          this.assignPasses();
                        } else this.onMissingClick();
                      }}
                      className={`btn btn-primary btn-assign-passes ${!isValid ? 'disabled' : ''}`}
                      type='button'
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {!season && arrivalDate && (
          <div className='row'>
            <div className='col-xs-12'>
              <div className='alert alert-warning'>
                Sorry, no season found for this date
              </div>
            </div>
          </div>
        )}
      </MainContainer>
    );
  }
}

export default connect(
  mapStateToProps(
    'merch', 'parks', 'seasons', 'billingContact', 'ticketTypes', 'errors',
    'arrivalInformation', 'countries', 'categories', 'requestError',
  ),
  mapDispatchToProps(
    partyActions, billingInformationActions, countriesActions, errorActions,
    arrivalInformationActions, parksActions, seasonsActions, ticketTypesActions,
    requestActions, errorActions, merchActions,
  ),
)(CreateParty);
