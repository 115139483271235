import PropTypes from 'prop-types';
import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import OverlayTooltip from '../overlay-tooltip';
import { ValidationError } from '../validator/validator';

const Wrapper = function (props) {
  const { className } = props;
  return (
    <div className={className}>
      {props.children}
    </div>
  );
};


export default class FormField extends React.Component {
  static propTypes = {
    label: PropTypes.node,
    tooltip: PropTypes.node,
    secondTooltip: PropTypes.string,
    id: PropTypes.string,
    refInput: PropTypes.func,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    prependIcon: PropTypes.string,
    componentClass: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.func,
      PropTypes.instanceOf(React.Component),
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    required: PropTypes.bool,
    type: PropTypes.string,
    options: PropTypes.array,
    isStatic: PropTypes.bool,
    isInline: PropTypes.bool,
    labelSize: PropTypes.number,
    fieldSize: PropTypes.number,
    placeholder: PropTypes.string,
    validators: PropTypes.array,
  };

  static defaultProps = {
    refInput: () => {},
    label: null,
    tooltip: null,
    secondTooltip: null,
    prependIcon: null,
    componentClass: null,
    id: null,
    onChange: null,
    options: null,
    value: '',
    placeholder: null,
    validators: [],
    isStatic: false,
    isInline: false,
    required: false,
    labelSize: 2,
    fieldSize: 10,
    type: 'text',
  };

  componentDidMount() {
    const { componentClass } = this.props;
    if (componentClass === 'phone') {
      const phone = document.querySelector('.rrui__input-element.rrui__button-reset.rrui__select__button');
      if (!phone) return;
      phone.setAttribute('tabindex', -1);
    }
  }

  changeValue(evt, name) {
    if (this.props.onChange) {
      const value = evt instanceof Object && evt.target ? evt.target.value : evt;
      this.props.onChange(value, name);
    }
  }

  validate(value) {
    const errors = [];
    this.props.validators.forEach((validator) => {
      try {
        validator.validate(value);
      } catch (error) {
        if (error instanceof ValidationError) {
          errors.push(error.message);
        } else {
          throw error;
        }
      }
    });

    return errors;
  }

  renderLabel(label, isInline, labelSize) {
    const name = this.props.id || this.props.name;
    const { tooltip, secondTooltip } = this.props;
    let controlLabel = (
      <span>
        <label htmlFor={name}>
          {label}
          &nbsp;
        </label>
        {tooltip && <OverlayTooltip tooltip={tooltip} id={`tooltip_${name}`} aria-hidden='true' />}
        {secondTooltip && <OverlayTooltip
          tooltip={secondTooltip}
          id={`secondTooltip_${name}`}
          icon={<i style={{ color: '#db5c1d' }} className='fa fa-fw fa-exclamation-circle' />}
        />}
      </span>
    );
    if (isInline) {
      controlLabel = <Wrapper className={`col-xs-${labelSize}`}>{controlLabel}</Wrapper>;
    }
    return controlLabel;
  }

  renderField(properties, isInline, fieldSize) {
    const {
      name,
      prependIcon,
      isStatic,
      onChange,
      options,
      componentClass,
      country,
      refInput,
      secondTooltip, // just for taking it out from props and get rid of the Unknown Props warning from React
      children,
      ...props
    } = properties;
    props.value = props.value || '';
    let formControl = null;

    if (isStatic !== true) {
      if (componentClass === 'select') {
        const className = `form-control ${props.className ? props.className : ''}`;
        formControl = (
          <select
            ref={refInput}
            id={properties.id || properties.name}
            placeholder={props.placeholder || ''}
            onChange={evt => this.changeValue(evt, name)}
            {...props}
            className={className}
          >
            {
              options.map(option => (
                <option key={option.value} value={option.value}>{option.name}</option>
              ))
            }
          </select>
        );
      } else if (componentClass === 'phone') {
        const className = `form-control ${props.className ? props.className : ''}`;
        formControl = (
          <PhoneInput
            id={properties.id || properties.name}
            defaultCountry={country || 'AU'}
            ref={ref => refInput(ref)}
            placeholder={props.placeholder || ''}
            className={className}
            onChange={(phone) => {
              if (this.props.onChange) {
                this.props.onChange(phone, name);
              }
            }}
            {...props}
          />
        );
      } else if (componentClass !== null) {
        formControl = React.isValidElement(componentClass) ?
          React.cloneElement(componentClass, Object.assign(props, { options, onChange, name })) :
          React.createElement(componentClass, Object.assign(props, { options, onChange, name }));
      } else {
        const className = `form-control ${props.className ? props.className : ''}`;
        formControl = (
          <input
            ref={refInput}
            type='text'
            id={properties.id || properties.name}
            placeholder={props.placeholder || ''}
            onChange={evt => this.changeValue(evt, name)}
            {...props}
            className={className}
          />
        );
      }
    } else {
      formControl = (<p className='form-control-static'>{props.value}</p>);
    }

    if ((prependIcon || children) && !isStatic) {
      formControl = (
        <div className='input-group'>
          {prependIcon && (
            <div className='input-group-addon'>
              <i className={prependIcon} />
            </div>
          )}
          {formControl}
          {children}
        </div>
      );
    }

    if (isInline) {
      formControl = <Wrapper className={`col-xs-${fieldSize}`}>{formControl}</Wrapper>;
    }
    return formControl;
  }

  render() {
    const {
      id, label, isInline, labelSize,
      helpText, fieldSize, validationState: customValidationState, ...props
    } = this.props;
    const isInvalid = this.validate(props.value).length > 0;
    const isEmpty = value => value == null || value === '';

    let validationState;
    if (props.required && isEmpty(props.value)) {
      validationState = 'error';
    } else if (props.required) {
      validationState = isInvalid ? 'error' : 'success';
    }
    return (
      <div className={`form-group has-${customValidationState ?? validationState}`}>
        {label && this.renderLabel(label, isInline, labelSize)}
        {this.renderField(props, isInline, fieldSize)}
        {helpText && <span className='help-block'>{helpText}</span>}
      </div>
    );
  }
}
